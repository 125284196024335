import { useTranslation } from "react-i18next";

function PaymentFailed() {
    const { t } = useTranslation()
    return (
        <div className="container" >
            <div className="col-10 col-md-5 card p-2 mt-4 mx-auto border-0 shadow">
                <div className="row">
                    <div className="col-6 mx-auto my-4">
                        <svg version="1.1" className="card-img-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#db3646" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <line className="path line" fill="none" stroke="#db3646" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                            <line className="path line" fill="none" stroke="#db3646" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="37.9" x2="34.4" y2="92.3" />
                        </svg>
                    </div>
                </div>

                <div className="card-body text-center">
                    <h4 className="text-danger mt-3"></h4>
                    <p className="mt-3">
                        {t('transactionErrorText')}</p>
                </div>
            </div>
        </div>);
}

export default PaymentFailed;