import React, { useState, useEffect, useRef } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import { RelaiMapSelection } from "../../../components/map/map";
import PaginationComponent from "../../../components/paginations/pagination";
import ColisCollapse from "../../../components/colisCollapse/colisCollapse";
import { useNavigate } from "react-router-dom";
import style from "../style.module.css"
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import { useTranslation } from "react-i18next";

function MesColis({ openChat }) {
    const modalRef1 = useRef(null)
    const modalRef2 = useRef(null)
    const modalRef3 = useRef(null)
    const modalRef4 = useRef(null)
    const modalRef5 = useRef(null)
    const [error1, setError1] = useState()
    const [error12, setError12] = useState()
    const [error2, setError2] = useState()
    const [error22, setError22] = useState()
    const [error3, setError3] = useState()
    const [offreRelai1, setOffreRelai1] = useState()
    const [offreRelai2, setOffreRelai2] = useState()
    const { t } = useTranslation()

    const [code, setCode] = useState()
    const [demandes, setDemandes] = useState([]);
    const [postules, setPostules] = useState([]);
    const [selectedOffre, setSelectedOffre] = useState(null)
    const [relai1, setRelai1] = useState(false)
    const [relai2, setRelai2] = useState(false)
    const [counterRelai1, setCounterRelai1] = useState(false)
    const [counterRelai2, setCounterRelai2] = useState(false)
    const [page1, setPage1] = useState(1)
    const [lastPage1, setLastPage1] = useState()
    const [page2, setPage2] = useState(1)
    const [lastPage2, setLastPage2] = useState()
    const fetchCount1 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/trajets/userCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage1(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchCount2 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/TtrajetOffres/sentCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage2(calculatedLastPage);
            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    }
    const accepter = async () => {
        try {
            const id = selectedOffre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/accepter/${id}`);
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    const verify = () => {
        console.log(selectedOffre)
        let error1, error2, error12, error22, error3 = false
        if (relai1 || !selectedOffre.offre.mam1) {
            if (relai1 && !selectedOffre.offre.relai1) {
                console.log("Error 12")
                error12 = true
            }
            else if (!selectedOffre.offre.mam1 && !relai1) {
                console.log("Error 1")
                error1 = true
            }
        }
        if (relai2 || !selectedOffre.offre.mam2) {
            if (relai2 && !selectedOffre.offre.relai2) {
                console.log("Error 22")
                error22 = true
            }
            else if (!selectedOffre.offre.mam2 && !relai2) {
                console.log("Error 2")
                error2 = true

            }

        }
        if (!selectedOffre.offre.prix || selectedOffre.offre.prix <= 0)
            error3 = true
        setError1(error1)
        setError2(error2)
        setError3(error3)
        setError12(error12)
        setError22(error22)
        if (!error1 && !error2 && !error12 && !error22 && !error3)
            return true
        else
            return false
    }
    const submit = async () => {
        try {
            if (!verify())
                return
            const id = selectedOffre._id
            console.log(`Updating offre ${id}`);
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/${id}`, selectedOffre.offre);
            console.log("Offre updated");
            console.log(response.data);
            fetchData2();
        } catch (error) {
            console.error("Error updating offre:", error);
        }
    }
    const handleRelaiSelection1 = async (place) => {
        console.log("Setting relai1");
        let offreSelection = selectedOffre
        offreSelection.offre.relai1 = place
        offreSelection.offre.mam1 = false
        setSelectedOffre(offreSelection)
        console.log(offreSelection.counterOffre.relai1)
        let n = await getRelai(place)
        setOffreRelai1(n)
    };
    const handleRelaiSelection2 = async (place) => {
        console.log("Setting relai2");
        let offreSelection = selectedOffre
        offreSelection.offre.relai2 = place
        offreSelection.offre.mam2 = false
        setSelectedOffre(offreSelection)
        console.log(offreSelection.counterOffre.relai1)
        let n = await getRelai(place)
        setOffreRelai2(n)
    };

    const handleOffreSelection = async (row) => {
        let offreSelection = structuredClone(row.offre);
        setSelectedOffre(offreSelection);
        const n4 = await getRelai(offreSelection.offre.relai2)
        if (offreSelection.offre.relai1) {
            const n1 = await getRelai(offreSelection.counterOffre.relai1)
            setCounterRelai1(n1.name)
        }
        if (offreSelection.offre.relai2) {
            const n2 = await getRelai(offreSelection.counterOffre.relai2)
            setCounterRelai2(n2.name)
        }
        if (offreSelection.offre.relai1) {
            const n3 = await getRelai(offreSelection.offre.relai1)
            setOffreRelai1(n3)
        }
        if (offreSelection.offre.relai2) {
            const n4 = await getRelai(offreSelection.offre.relai2)
            setOffreRelai2(n4)
        }

        if (offreSelection && offreSelection.offre && offreSelection.offre.relai2) {
            setRelai2(true)
        }
        else {
            setRelai2(false)
        }
        if (offreSelection && offreSelection.offre && offreSelection.offre.relai1) {
            setRelai1(true)
        }
        else {
            setRelai1(false)
        }
        console.log(offreSelection)
    }
    const getRelai = async (id) => {
        try {
            let address
            await axios.get(`${process.env.REACT_APP_SERVER}/relais/${id}`).then((res) => {
                address = res.data
            }).catch((err) => {
                console.log(err)
            });
            return address
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const columns2 = t("ttrajetOffreCols", { returnObjects: true });
    const columns = t("trajetCols", { returnObjects: true });
    const fetchData1 = async (page) => {
        try {

            await axios.get(`${process.env.REACT_APP_SERVER}/trajets/UserTrajets?page=${page}`)
                .then(async (trajetResponse) => {
                    const trajetData = trajetResponse.data;
                    const promises = trajetData.map(async (TrajetItem) => {
                        try {
                            const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                            TrajetItem.colis = colisResponse.data ?? [];

                            return TrajetItem;
                        } catch (error) {
                            console.error("Error fetching colis and offres data:", error);
                            return null; // Returning null to indicate failure
                        }
                    });

                    const updateddemandesData = await Promise.all(promises);
                    const filtereddemandesData = updateddemandesData.filter(item => item !== null);
                    console.log(filtereddemandesData)
                    setPostules(filtereddemandesData);
                }).catch((error) => {
                    setPostules([])
                    return
                })

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchData2 = async (page) => {
        let offresData = []
        try {
            await axios.get(`${process.env.REACT_APP_SERVER}/TtrajetOffres?page=${page}`)
                .then(async (res) => {
                    offresData = res.data;
                    console.log(offresData);
                    const promises = offresData.map(async (OffreItem) => {
                        try {
                            let trajet = {}
                            await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/${OffreItem.ttrajet}`)
                                .then(async (res) => {
                                    trajet = res.data
                                    trajet.colis = []
                                    trajet.offre = OffreItem
                                    for (let coli of OffreItem.colis) {
                                        await axios.get(`${process.env.REACT_APP_SERVER}/colis/${coli}`)
                                            .then((res) => {
                                                trajet.colis.push(res.data)
                                            })
                                    }
                                });
                            return trajet;
                        } catch (error) {
                            console.error("Error fetching colis and offres data:", error.message);
                            return null; // Returning null to indicate failure
                        }
                    });
                    const updateddemandesData = await Promise.all(promises);
                    const filtereddemandesData = updateddemandesData.filter(item => item !== null);
                    console.log(filtereddemandesData)
                    setDemandes(filtereddemandesData);
                })
                .catch((error) => {
                    setDemandes([])
                    return
                })

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const navigate = useNavigate()
    useEffect(() => {
        fetchCount1()
        fetchCount2()
        fetchData1(page1)
        fetchData2(page2)
    }, []);
    async function removeRow2(index) {
        try {
            console.log(demandes[index]);
            if (demandes[index].transporteur) {
                const id = demandes[index].offre._id;
                try {
                    await axios.delete(`${process.env.REACT_APP_SERVER}/TtrajetOffres/${id}`).then((res) => {
                        fetchData2(page2)
                    })

                } catch (offreError) {
                    console.error(`Failed to delete TtrajetOffres with id ${id}:`, offreError);
                    return;
                }
            }
        } catch (error) {
            console.error('Unexpected error while deleting item:', error);
        }
    }
    async function removeRow1(index) {
        try {
            console.log(postules[index]);

            const id = postules[index]._id;

            // Delete the "trajets" and handle errors
            try {
                const response = await axios.delete(`${process.env.REACT_APP_SERVER}/trajets/${id}`);

                // Check if the response is successful and update the state
                if (response.status === 200) {
                    fetchData1(page1)
                } else {
                    console.error('Failed to delete item:', response);
                }
            } catch (deleteError) {
                console.error(`Failed to delete trajet with id ${id}:`, deleteError);
            }
        } catch (error) {
            console.error('Unexpected error while deleting item:', error);
        }
    }
    const showCode = async (id) => {
        try {
            await axios.get(`${process.env.REACT_APP_SERVER}/receiveCode/${id}`).then((res) => {
                setCode(res.data.code)
                openModal(modalRef5)
            })
        } catch (error) {

        }
    }
    return (
        <Row style={{ maxWidth: "100%" }}>
            <nav>
                <div className={`${style.navs} nav nav-tabs`} id="nav-tab" role="tablist">
                    <button className="nav-link active w-50" id="nav-colis-tab" data-bs-toggle="tab" data-bs-target="#nav-colis" type="button" role="tab" aria-controls="nav-colis" aria-selected="true"> <h5 className="text-start fw-lighter m-0">{t('posted')}</h5> </button>
                    <button className="nav-link w-50" id="nav-demande-tab" data-bs-toggle="tab" data-bs-target="#nav-demande" type="button" role="tab" aria-controls="nav-demande" aria-selected="false"><h5 className="text-start fw-lighter m-0">{t('demanded')}</h5> </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-colis" role="tabpanel" aria-labelledby="nav-colis-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">{t('postedPackages')}</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion1">
                                    {postules.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                {columns.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (

                                                            row.etat === "postulé" ? (
                                                                <>
                                                                    <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                        <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => removeRow1(index)}>
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                <path d="M9.16999 15.5804C8.97999 15.5804 8.78999 15.5104 8.63999 15.3604C8.34999 15.0704 8.34999 14.5904 8.63999 14.3004L14.3 8.64035C14.59 8.35035 15.07 8.35035 15.36 8.64035C15.65 8.93035 15.65 9.41035 15.36 9.70035L9.69998 15.3604C9.55998 15.5104 9.35999 15.5804 9.16999 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                <path d="M14.83 15.5804C14.64 15.5804 14.45 15.5104 14.3 15.3604L8.63999 9.70035C8.34999 9.41035 8.34999 8.93035 8.63999 8.64035C8.92999 8.35035 9.40998 8.35035 9.69998 8.64035L15.36 14.3004C15.65 14.5904 15.65 15.0704 15.36 15.3604C15.21 15.5104 15.02 15.5804 14.83 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                    {/* <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => removeRow1(index)}>Supprimer</button>*/}
                                                                </>
                                                            ) : row.etat === "en cours" ? (
                                                                <>
                                                                    <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                        <button className="btn btn-outline-primary btn-sm w-auto " onClick={() => navigate('/suivi', { state: { type: "trajet", id: row._id } })}>
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 14.1699C9.87 14.1699 8.13 12.4399 8.13 10.2999C8.13 8.15994 9.87 6.43994 12 6.43994C14.13 6.43994 15.87 8.16994 15.87 10.3099C15.87 12.4499 14.13 14.1699 12 14.1699ZM12 7.93994C10.7 7.93994 9.63 8.99994 9.63 10.3099C9.63 11.6199 10.69 12.6799 12 12.6799C13.31 12.6799 14.37 11.6199 14.37 10.3099C14.37 8.99994 13.3 7.93994 12 7.93994Z" fill="currentColor" fill-opacity="0.88" />
                                                                                <path d="M12 22.76C10.52 22.76 9.02999 22.2 7.86999 21.09C4.91999 18.25 1.65999 13.72 2.88999 8.33C3.99999 3.44 8.26999 1.25 12 1.25C12 1.25 12 1.25 12.01 1.25C15.74 1.25 20.01 3.44 21.12 8.34C22.34 13.73 19.08 18.25 16.13 21.09C14.97 22.2 13.48 22.76 12 22.76ZM12 2.75C9.08999 2.75 5.34999 4.3 4.35999 8.66C3.27999 13.37 6.23999 17.43 8.91999 20C10.65 21.67 13.36 21.67 15.09 20C17.76 17.43 20.72 13.37 19.66 8.66C18.66 4.3 14.91 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                    {/*<button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => navigate('/suivi', { state: { type: "trajet", id: row._id } })
                                                                    }>Suivi</button>*/}

                                                                </>
                                                            ) : row.etat === "accepté" ? (
                                                                <button className="btn btn-outline-success  btn-sm my-1 col-12" onClick={() => { navigate(`/payement`, { state: row }) }}>Payer</button>
                                                            ) : (
                                                                <button className="btn btn-outline-secondary disabled btn-sm my-1 col-12">Aucune</button>
                                                            )
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : t('notFound')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : t('notFound')
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.prix ? row.prix : t('notFound')
                                                        ) : (
                                                            row[column[1]] || t('notFound')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>

                        </CardBody>
                        <PaginationComponent page={page1} lastPage={lastPage1} setPage={setPage1} fetchData={fetchData1} />

                    </Card>

                </div>
                <div className="tab-pane fade" id="nav-demande" role="tabpanel" aria-labelledby="nav-demande-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">{t('demandPackages')}</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns2.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion2">
                                    {demandes.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr >
                                                {columns2.map(column => (
                                                    <td key={column[1]} className=" align-middle  border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "en cours" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-primary btn-sm my-1 " onClick={() => { showCode(row.offre._id) }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M18 10.75C17.59 10.75 17.25 10.41 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.75 10.41 6.41 10.75 6 10.75C5.59 10.75 5.25 10.41 5.25 10V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8V10C18.75 10.41 18.41 10.75 18 10.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 19.25C10.21 19.25 8.75 17.79 8.75 16C8.75 14.21 10.21 12.75 12 12.75C13.79 12.75 15.25 14.21 15.25 16C15.25 17.79 13.79 19.25 12 19.25ZM12 14.25C11.04 14.25 10.25 15.04 10.25 16C10.25 16.96 11.04 17.75 12 17.75C12.96 17.75 13.75 16.96 13.75 16C13.75 15.04 12.96 14.25 12 14.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M17 22.75H7C2.59 22.75 1.25 21.41 1.25 17V15C1.25 10.59 2.59 9.25 7 9.25H17C21.41 9.25 22.75 10.59 22.75 15V17C22.75 21.41 21.41 22.75 17 22.75ZM7 10.75C3.42 10.75 2.75 11.43 2.75 15V17C2.75 20.57 3.42 21.25 7 21.25H17C20.58 21.25 21.25 20.57 21.25 17V15C21.25 11.43 20.58 10.75 17 10.75H7Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>
                                                                            <button className="btn btn-outline-primary  btn-sm my-1" onClick={() => navigate('/suivi', { state: { type: "ttrajet", id: row.offre._id } })}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 14.1699C9.87 14.1699 8.13 12.4399 8.13 10.2999C8.13 8.15994 9.87 6.43994 12 6.43994C14.13 6.43994 15.87 8.16994 15.87 10.3099C15.87 12.4499 14.13 14.1699 12 14.1699ZM12 7.93994C10.7 7.93994 9.63 8.99994 9.63 10.3099C9.63 11.6199 10.69 12.6799 12 12.6799C13.31 12.6799 14.37 11.6199 14.37 10.3099C14.37 8.99994 13.3 7.93994 12 7.93994Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 22.76C10.52 22.76 9.02999 22.2 7.86999 21.09C4.91999 18.25 1.65999 13.72 2.88999 8.33C3.99999 3.44 8.26999 1.25 12 1.25C12 1.25 12 1.25 12.01 1.25C15.74 1.25 20.01 3.44 21.12 8.34C22.34 13.73 19.08 18.25 16.13 21.09C14.97 22.2 13.48 22.76 12 22.76ZM12 2.75C9.08999 2.75 5.34999 4.3 4.35999 8.66C3.27999 13.37 6.23999 17.43 8.91999 20C10.65 21.67 13.36 21.67 15.09 20C17.76 17.43 20.72 13.37 19.66 8.66C18.66 4.3 14.91 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                            <button className="btn btn-outline-success btn-sm my-1" >
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => openChat(row.offre._id, "TtrajetOffre")}>
                                                                                    <path d="M15.5 11.25H8.5C8.09 11.25 7.75 10.91 7.75 10.5C7.75 10.09 8.09 9.75 8.5 9.75H15.5C15.91 9.75 16.25 10.09 16.25 10.5C16.25 10.91 15.91 11.25 15.5 11.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M16 22.3199C15.66 22.3199 15.32 22.22 15.03 22.03L10.77 19.1899H7C3.56 19.1899 1.25 16.8799 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.6199 20.77 18.84 17.75 19.15V20.5699C17.75 21.2199 17.4 21.8099 16.83 22.1099C16.57 22.2499 16.28 22.3199 16 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.4299C2.75 16.0099 4.42 17.6799 7 17.6799H11C11.15 17.6799 11.29 17.7199 11.42 17.8099L15.87 20.77C15.98 20.84 16.08 20.81 16.13 20.78C16.18 20.75 16.26 20.6899 16.26 20.5599V18.4299C16.26 18.0199 16.6 17.6799 17.01 17.6799C19.59 17.6799 21.26 16.0099 21.26 13.4299V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>

                                                                        {/*        <button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => navigate('/suivi', { state: { type: "ttrajet", id: row.offre._id } })}>Suivi</button>
                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12">Discuter</button>
                                                                */ }   </>
                                                                ) : row.offre.etat === "accepté" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-success btn-sm my-1 " onClick={() => { navigate(`/payement`, { state: row }) }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M13.05 16.25H11.17C9.84001 16.25 8.75 15.13 8.75 13.75C8.75 13.34 9.09 13 9.5 13C9.91 13 10.25 13.34 10.25 13.75C10.25 14.3 10.66 14.75 11.17 14.75H13.05C13.44 14.75 13.75 14.4 13.75 13.97C13.75 13.43 13.6 13.35 13.26 13.23L10.25 12.18C9.61 11.95 8.75 11.49 8.75 10.02C8.75 8.76999 9.74001 7.73999 10.95 7.73999H12.83C14.16 7.73999 15.25 8.85999 15.25 10.24C15.25 10.65 14.91 10.99 14.5 10.99C14.09 10.99 13.75 10.65 13.75 10.24C13.75 9.68999 13.34 9.23999 12.83 9.23999H10.95C10.56 9.23999 10.25 9.58999 10.25 10.02C10.25 10.56 10.4 10.64 10.74 10.76L13.75 11.81C14.39 12.04 15.25 12.5 15.25 13.97C15.25 15.23 14.26 16.25 13.05 16.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 17.25C11.59 17.25 11.25 16.91 11.25 16.5V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V16.5C12.75 16.91 12.41 17.25 12 17.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2C12.75 2.41 12.41 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 11.59 21.59 11.25 22 11.25C22.41 11.25 22.75 11.59 22.75 12C22.75 17.93 17.93 22.75 12 22.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M22 6.75C21.59 6.75 21.25 6.41 21.25 6V2.75H18C17.59 2.75 17.25 2.41 17.25 2C17.25 1.59 17.59 1.25 18 1.25H22C22.41 1.25 22.75 1.59 22.75 2V6C22.75 6.41 22.41 6.75 22 6.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M16.9999 7.74994C16.8099 7.74994 16.6199 7.67994 16.4699 7.52994C16.1799 7.23994 16.1799 6.75994 16.4699 6.46994L21.4699 1.46994C21.7599 1.17994 22.2399 1.17994 22.5299 1.46994C22.8199 1.75994 22.8199 2.23994 22.5299 2.52994L17.5299 7.52994C17.3799 7.67994 17.1899 7.74994 16.9999 7.74994Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                        {/*<button className="btn btn-outline-success  btn-sm my-1 col-12" onClick={() => { navigate(`/payement`, { state: row }) }}>Payer</button>*/}
                                                                    </>
                                                                ) : row.offre.etat === "demandé" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-primary  btn-sm my-1 " onClick={() => {
                                                                                openModal(modalRef1);
                                                                                handleOffreSelection(row)
                                                                            }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 16.3299C9.61001 16.3299 7.67001 14.3899 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C14.39 7.66992 16.33 9.60992 16.33 11.9999C16.33 14.3899 14.39 16.3299 12 16.3299ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 13.5599 10.44 14.8299 12 14.8299C13.56 14.8299 14.83 13.5599 14.83 11.9999C14.83 10.4399 13.56 9.16992 12 9.16992Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 21.02C8.23999 21.02 4.68999 18.82 2.24999 15C1.18999 13.35 1.18999 10.66 2.24999 8.99998C4.69999 5.17998 8.24999 2.97998 12 2.97998C15.75 2.97998 19.3 5.17998 21.74 8.99998C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02ZM12 4.47998C8.76999 4.47998 5.67999 6.41998 3.51999 9.80998C2.76999 10.98 2.76999 13.02 3.51999 14.19C5.67999 17.58 8.76999 19.52 12 19.52C15.23 19.52 18.32 17.58 20.48 14.19C21.23 13.02 21.23 10.98 20.48 9.80998C18.32 6.41998 15.23 4.47998 12 4.47998Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>
                                                                            <button className="btn btn-outline-danger  btn-sm my-1 " onClick={() => removeRow2(index)} >
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M9.16999 15.5804C8.97999 15.5804 8.78999 15.5104 8.63999 15.3604C8.34999 15.0704 8.34999 14.5904 8.63999 14.3004L14.3 8.64035C14.59 8.35035 15.07 8.35035 15.36 8.64035C15.65 8.93035 15.65 9.41035 15.36 9.70035L9.69998 15.3604C9.55998 15.5104 9.35999 15.5804 9.16999 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M14.83 15.5804C14.64 15.5804 14.45 15.5104 14.3 15.3604L8.63999 9.70035C8.34999 9.41035 8.34999 8.93035 8.63999 8.64035C8.92999 8.35035 9.40998 8.35035 9.69998 8.64035L15.36 14.3004C15.65 14.5904 15.65 15.0704 15.36 15.3604C15.21 15.5104 15.02 15.5804 14.83 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>
                                                                        </div>
                                                                        {/* <button className="btn btn-outline-primary btn-sm my-1 col-12" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => {
                                                                            handleOffreSelection(row)
                                                                        }}>Voir</button>
                                                                        <button className="btn btn-outline-danger my-1 col-12 btn-sm" onClick={() => removeRow2(index)}>Supprimer</button>*/}
                                                                    </>
                                                                ) : (
                                                                    <button className="btn btn-outline-secondary disabled btn-sm my-1 col-12">Aucune</button>
                                                                )}
                                                            </>
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : t('notFound')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : t('notFound')
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.offre.prix ? row.offre.offre.prix : t('notFound')
                                                        ) : column[1] === "counteroffre.prix" ? (
                                                            row.offre?.counterOffre?.prix ? row.offre.counterOffre.prix : t('notFound')
                                                        ) : column[1] === "etat" ? (
                                                            row.offre.etat ? row.offre.etat : t('notFound')
                                                        ) : (
                                                            row[column[1]] || t('notFound')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns2.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                        <PaginationComponent page={page2} lastPage={lastPage2} setPage={setPage2} fetchData={fetchData2} />

                    </Card>
                    <div className="modal modal-xl fade " ref={modalRef1} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" >
                            <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                                <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                                    <h1 className="modal-title fs-5 text-light" id="exampleModalLabel">{t('consultOffre')}</h1>
                                    <button type="button" className="btn-close" onClick={() => { closeModal(modalRef1) }} aria-label="Close"></button>
                                </div>
                                <div className="modal-body" >
                                    <div className="row">
                                        <div className="col ">
                                            <div className="card rounded-5 p-3 h-100" >
                                                <h5 className="card-title">{t('receivedOffre')}</h5>
                                                <div className="card-body">
                                                    <h6 className="fw-bold">{t('start')}</h6>
                                                    <p>
                                                        {t('startH2H')} {selectedOffre && selectedOffre.offre.mam1 === true ? t('yes') : t('no')}
                                                    </p>
                                                    <p>
                                                        {t('startRelai')} {(selectedOffre && selectedOffre.offre.relai1) ? (counterRelai1) : (t('no'))}
                                                    </p>
                                                    <h6 className="fw-bold">{t('end')}</h6>
                                                    <p>
                                                        {t('endH2H')} {selectedOffre && selectedOffre.offre.mam2 === true ? t('yes') : t('no')}
                                                    </p>
                                                    <p>
                                                        {t('endRelai')} {(selectedOffre && selectedOffre.offre.relai2) ? (counterRelai2) : (t('no'))}
                                                    </p>
                                                    <div> <b>{t('price')} : </b> {(selectedOffre && selectedOffre.offre.prix) ? (selectedOffre.offre.prix) : (t('notFound'))}</div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card rounded-5 p-3 h-100" >
                                                <h5 className="card-title">{t('yourOffre')}</h5>
                                                <div className="card-body">
                                                    <fieldset>
                                                        <h6 className="fw-bold">{t('start')}</h6>
                                                        <div className="d-flex">
                                                            <p className="me-4">{t('startH2H')}</p>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam1" // Separate name for this group
                                                                    id="r1"
                                                                    value='oui'
                                                                    checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.mam1}
                                                                    onChange={() => {
                                                                        let offreSelection = { ...selectedOffre, offre: { ...selectedOffre.offre, mam1: true } };
                                                                        setSelectedOffre(offreSelection);
                                                                        setRelai1(false)
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r1">{t('yes')}</label>
                                                            </div>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam1"
                                                                    id="r2"
                                                                    value="non"
                                                                    checked={selectedOffre && selectedOffre.offre && !selectedOffre.offre.mam1}
                                                                    onChange={(event) => {

                                                                        let offreSelection = { ...selectedOffre, offre: { ...selectedOffre.offre, mam1: false } };
                                                                        setSelectedOffre(offreSelection);
                                                                        setRelai1(true)
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r2">{t('no')}</label>
                                                            </div>
                                                        </div>
                                                        < div className="d-flex flex-wrap align-items-start ">
                                                            <p className="me-4">{t('startRelai')}</p>
                                                            {!selectedOffre?.offre.relai1 && <>
                                                                <div className="form-check me-4">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="relai1" // Separate name for this group
                                                                        id="r3"
                                                                        value='oui'
                                                                        checked={relai1}

                                                                        onChange={(event) => {
                                                                            let offreSelection = { ...selectedOffre, offre: { ...selectedOffre.offre, mam1: false } };
                                                                            setSelectedOffre(offreSelection);
                                                                            setRelai1(true);
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="r3">{t('yes')}</label>
                                                                </div>
                                                                <div className="form-check me-4">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="relai1"
                                                                        id="r4"
                                                                        value="non"
                                                                        checked={!relai1}
                                                                        onChange={(event) => {
                                                                            let offreSelection = { ...selectedOffre, offre: { ...selectedOffre.offre, mam1: true, relai1: undefined } };
                                                                            setSelectedOffre(offreSelection);
                                                                            setRelai1(false);
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="r4">{t('no')}</label>
                                                                </div>
                                                            </>}
                                                            {offreRelai1 && <>
                                                                <p className="me-2">{offreRelai1?.name}</p>
                                                                <div className="d-flex">
                                                                    <button className="btn btn-sm p-0" style={{ width: "30px" }} onClick={() => {

                                                                        openModal(modalRef2)
                                                                        closeModal(modalRef1)
                                                                    }}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M8.50032 17.6896C7.89032 17.6896 7.33032 17.4696 6.92032 17.0696C6.43032 16.5796 6.22032 15.8696 6.33032 15.1196L6.76032 12.1096C6.84032 11.5296 7.22032 10.7796 7.63032 10.3696L15.5104 2.48957C17.5004 0.49957 19.5204 0.49957 21.5104 2.48957C22.6004 3.57957 23.0904 4.68957 22.9904 5.79957C22.9004 6.69957 22.4204 7.57957 21.5104 8.47957L13.6304 16.3596C13.2204 16.7696 12.4704 17.1496 11.8904 17.2296L8.88032 17.6596C8.75032 17.6896 8.62032 17.6896 8.50032 17.6896ZM16.5704 3.54957L8.69032 11.4296C8.50032 11.6196 8.28032 12.0596 8.24032 12.3196L7.81032 15.3296C7.77032 15.6196 7.83032 15.8596 7.98032 16.0096C8.13032 16.1596 8.37032 16.2196 8.66032 16.1796L11.6704 15.7496C11.9304 15.7096 12.3804 15.4896 12.5604 15.2996L20.4404 7.41957C21.0904 6.76957 21.4304 6.18957 21.4804 5.64957C21.5404 4.99957 21.2004 4.30957 20.4404 3.53957C18.8404 1.93957 17.7404 2.38957 16.5704 3.54957Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M19.8505 9.83027C19.7805 9.83027 19.7105 9.82027 19.6505 9.80027C17.0205 9.06027 14.9305 6.97027 14.1905 4.34027C14.0805 3.94027 14.3105 3.53027 14.7105 3.41027C15.1105 3.30027 15.5205 3.53027 15.6305 3.93027C16.2305 6.06027 17.9205 7.75027 20.0505 8.35027C20.4505 8.46027 20.6805 8.88027 20.5705 9.28027C20.4805 9.62027 20.1805 9.83027 19.8505 9.83027Z" fill="black" fill-opacity="0.88" />
                                                                        </svg>
                                                                    </button>
                                                                    <button className="btn btn-sm p-0" style={{ width: "30px" }} onClick={() => {

                                                                        setSelectedOffre({ ...selectedOffre, offre: { ...selectedOffre?.offre, relai1: undefined, mam1: true } });

                                                                        setOffreRelai1(undefined)
                                                                        setRelai1(false)

                                                                    }}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M21 6.73046C20.98 6.73046 20.95 6.73046 20.92 6.73046C15.63 6.20046 10.35 6.00046 5.11998 6.53046L3.07998 6.73046C2.65998 6.77046 2.28998 6.47046 2.24998 6.05046C2.20998 5.63046 2.50998 5.27046 2.91998 5.23046L4.95998 5.03046C10.28 4.49046 15.67 4.70046 21.07 5.23046C21.48 5.27046 21.78 5.64046 21.74 6.05046C21.71 6.44046 21.38 6.73046 21 6.73046Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M15.21 22.7496H8.79002C5.30002 22.7496 5.16002 20.8196 5.05002 19.2596L4.40002 9.18959C4.37002 8.77959 4.69002 8.41959 5.10002 8.38959C5.52002 8.36959 5.87002 8.67959 5.90002 9.08959L6.55002 19.1596C6.66002 20.6796 6.70002 21.2496 8.79002 21.2496H15.21C17.31 21.2496 17.35 20.6796 17.45 19.1596L18.1 9.08959C18.13 8.67959 18.49 8.36959 18.9 8.38959C19.31 8.41959 19.63 8.76959 19.6 9.18959L18.95 19.2596C18.84 20.8196 18.7 22.7496 15.21 22.7496Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M13.66 17.25H10.33C9.91999 17.25 9.57999 16.91 9.57999 16.5C9.57999 16.09 9.91999 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="black" fill-opacity="0.88" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </>}
                                                        </div>

                                                        {relai1 === true && !offreRelai1 && (
                                                            <button
                                                                className="btn btn-outline-primary col-12 mb-4"
                                                                onClick={() => {
                                                                    closeModal(modalRef1)
                                                                    openModal(modalRef2)
                                                                }} >
                                                                {t('selectRelai')}
                                                            </button>
                                                        )}
                                                        {error1 && <div className="alert alert-danger">
                                                            Veuillez sélectionner une option
                                                        </div>}
                                                        {error12 && <div className="alert alert-danger">
                                                            Veuillez sélectionner une point relais
                                                        </div>}
                                                    </fieldset>

                                                    <fieldset>
                                                        <h6 className="fw-bold">{t('end')}</h6>

                                                        <div className="d-flex">
                                                            <p className="me-4">{t('endH2H')}</p>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam2" // Separate name for this group
                                                                    id="r5"
                                                                    value='oui'
                                                                    checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.mam2}

                                                                    onChange={(event) => {
                                                                        let offreSelection = { ...selectedOffre, offre: { ...selectedOffre.offre, mam2: true } };
                                                                        setSelectedOffre(offreSelection);
                                                                        setRelai2(false)
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r5">{t('yes')}</label>
                                                            </div>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam2"
                                                                    id="r6"
                                                                    value="non"
                                                                    checked={selectedOffre && selectedOffre.offre && !selectedOffre.offre.mam2}
                                                                    onChange={(event) => {
                                                                        let offreSelection = { ...selectedOffre, offre: { ...selectedOffre.offre, mam2: false } };
                                                                        setSelectedOffre(offreSelection);
                                                                        setRelai2(true)
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r6">{t('no')}</label>
                                                            </div>
                                                        </div>

                                                        < div className="d-flex flex-wrap align-items-start  ">
                                                            <p className="me-4 ">{t('endRelai')}</p>
                                                            {!selectedOffre?.offre.relai2 && <>
                                                                <div className="form-check me-4">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="relai2" // Separate name for this group
                                                                        id="r3"
                                                                        value='oui'
                                                                        checked={relai2}

                                                                        onChange={(event) => {
                                                                            let offreSelection = { ...selectedOffre, offre: { ...selectedOffre.offre, mam2: false } };
                                                                            setSelectedOffre(offreSelection);
                                                                            setRelai2(true);
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="r3">{t('yes')}</label>
                                                                </div>
                                                                <div className="form-check me-4">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="relai2"
                                                                        id="r4"
                                                                        value="non"
                                                                        checked={!relai2}
                                                                        onChange={(event) => {
                                                                            let offreSelection = { ...selectedOffre, offre: { ...selectedOffre.offre, mam2: true, relai2: undefined } };
                                                                            setSelectedOffre(offreSelection);
                                                                            setRelai2(false);
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="r4">{t('no')}</label>
                                                                </div>
                                                            </>}
                                                            {offreRelai2 && <>
                                                                <p className="me-2">{offreRelai2?.name}</p>
                                                                <div className="d-flex">
                                                                    <button className="btn btn-sm p-0" style={{ width: "30px" }} onClick={() => {

                                                                        openModal(modalRef3)
                                                                        closeModal(modalRef1)
                                                                    }}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M8.50032 17.6896C7.89032 17.6896 7.33032 17.4696 6.92032 17.0696C6.43032 16.5796 6.22032 15.8696 6.33032 15.1196L6.76032 12.1096C6.84032 11.5296 7.22032 10.7796 7.63032 10.3696L15.5104 2.48957C17.5004 0.49957 19.5204 0.49957 21.5104 2.48957C22.6004 3.57957 23.0904 4.68957 22.9904 5.79957C22.9004 6.69957 22.4204 7.57957 21.5104 8.47957L13.6304 16.3596C13.2204 16.7696 12.4704 17.1496 11.8904 17.2296L8.88032 17.6596C8.75032 17.6896 8.62032 17.6896 8.50032 17.6896ZM16.5704 3.54957L8.69032 11.4296C8.50032 11.6196 8.28032 12.0596 8.24032 12.3196L7.81032 15.3296C7.77032 15.6196 7.83032 15.8596 7.98032 16.0096C8.13032 16.1596 8.37032 16.2196 8.66032 16.1796L11.6704 15.7496C11.9304 15.7096 12.3804 15.4896 12.5604 15.2996L20.4404 7.41957C21.0904 6.76957 21.4304 6.18957 21.4804 5.64957C21.5404 4.99957 21.2004 4.30957 20.4404 3.53957C18.8404 1.93957 17.7404 2.38957 16.5704 3.54957Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M19.8505 9.83027C19.7805 9.83027 19.7105 9.82027 19.6505 9.80027C17.0205 9.06027 14.9305 6.97027 14.1905 4.34027C14.0805 3.94027 14.3105 3.53027 14.7105 3.41027C15.1105 3.30027 15.5205 3.53027 15.6305 3.93027C16.2305 6.06027 17.9205 7.75027 20.0505 8.35027C20.4505 8.46027 20.6805 8.88027 20.5705 9.28027C20.4805 9.62027 20.1805 9.83027 19.8505 9.83027Z" fill="black" fill-opacity="0.88" />
                                                                        </svg>
                                                                    </button>
                                                                    <button className="btn btn-sm p-0" style={{ width: "30px" }} onClick={() => {

                                                                        setSelectedOffre({ ...selectedOffre, offre: { ...selectedOffre?.offre, relai2: undefined, mam2: true } });
                                                                        setOffreRelai2(undefined)
                                                                        setRelai2(false)

                                                                    }}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M21 6.73046C20.98 6.73046 20.95 6.73046 20.92 6.73046C15.63 6.20046 10.35 6.00046 5.11998 6.53046L3.07998 6.73046C2.65998 6.77046 2.28998 6.47046 2.24998 6.05046C2.20998 5.63046 2.50998 5.27046 2.91998 5.23046L4.95998 5.03046C10.28 4.49046 15.67 4.70046 21.07 5.23046C21.48 5.27046 21.78 5.64046 21.74 6.05046C21.71 6.44046 21.38 6.73046 21 6.73046Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M15.21 22.7496H8.79002C5.30002 22.7496 5.16002 20.8196 5.05002 19.2596L4.40002 9.18959C4.37002 8.77959 4.69002 8.41959 5.10002 8.38959C5.52002 8.36959 5.87002 8.67959 5.90002 9.08959L6.55002 19.1596C6.66002 20.6796 6.70002 21.2496 8.79002 21.2496H15.21C17.31 21.2496 17.35 20.6796 17.45 19.1596L18.1 9.08959C18.13 8.67959 18.49 8.36959 18.9 8.38959C19.31 8.41959 19.63 8.76959 19.6 9.18959L18.95 19.2596C18.84 20.8196 18.7 22.7496 15.21 22.7496Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M13.66 17.25H10.33C9.91999 17.25 9.57999 16.91 9.57999 16.5C9.57999 16.09 9.91999 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z" fill="black" fill-opacity="0.88" />
                                                                            <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="black" fill-opacity="0.88" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </>}
                                                        </div>
                                                        {relai2 === true && !offreRelai2 && (
                                                            <button className="btn btn-outline-primary col-12 mb-4"
                                                                onClick={() => {
                                                                    closeModal(modalRef1)
                                                                    openModal(modalRef3)
                                                                }} >{t('selectRelai')}</button>
                                                        )}
                                                        {error2 && <div className="alert alert-danger">
                                                            Veuillez sélectionner une option
                                                        </div>}
                                                        {error22 && <div className="alert alert-danger">
                                                            Veuillez sélectionner une point relais
                                                        </div>}
                                                    </fieldset>
                                                    <fieldset>
                                                        <h6 className="fw-bold">{t('price')}</h6>
                                                        <div>
                                                            <input className={`form-control ${error3 == true ? "is-invalid" : ""}`} type="number" min="1" placeholder="Saisir le prix" required value={selectedOffre?.offre?.prix ?? ''}
                                                                onChange={(event) => {
                                                                    let offreSelection = structuredClone(selectedOffre);
                                                                    offreSelection.offre.prix = Number(event.target.value);
                                                                    setSelectedOffre(offreSelection);
                                                                }} />
                                                            <div className="invalid-feedback">
                                                                Veuillez saisir le prix désiré
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal"
                                        onClick={() => {
                                            closeModal(modalRef1)
                                            setSelectedOffre(undefined)

                                        }}>{t("cancel")}</button>
                                    <button type="button" className="btn btn-outline-success"
                                        onClick={() => {
                                            closeModal(modalRef1)
                                            openModal(modalRef4)
                                        }}>{t('accept')}</button>
                                    <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal" onClick={() => {
                                        submit()
                                        setSelectedOffre(undefined)
                                    }}>{t('send')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade modal-xl" ref={modalRef2} data-bs-backdrop="static" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">{t('selectRelai')}</h1>
                                    <button type="button" className="btn-close" onClick={() => {
                                        closeModal(modalRef2)
                                        openModal(modalRef1)
                                    }} aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ height: "70vh" }}>
                                    <RelaiMapSelection
                                        setRelai={handleRelaiSelection1}
                                        nextModalRef={modalRef1}
                                        currentModal={modalRef2}
                                        location={selectedOffre?.offre?.offre?.relai1?.location ?? selectedOffre?.from_address?.location}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary"
                                        onClick={() => {
                                            closeModal(modalRef2)
                                            openModal(modalRef1)
                                        }}>{t("cancel")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade modal-xl" ref={modalRef3} data-bs-backdrop="static" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">{t('selectRelai')}</h1>
                                    <button type="button" className="btn-close" onClick={() => {
                                        closeModal(modalRef3)
                                        openModal(modalRef1)
                                    }} aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ height: "70vh" }}>
                                    <RelaiMapSelection
                                        setRelai={handleRelaiSelection2}
                                        nextModalId="exampleModal"
                                        location={selectedOffre?.offre?.offre?.relai2?.location ?? selectedOffre?.to_address?.location}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => {
                                        closeModal(modalRef3)
                                        openModal(modalRef1)
                                    }}>{t("cancel")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade " ref={modalRef4} aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">{t('acceptOffre')}</h1>
                                    <button type="button" className="btn-close"
                                        onClick={() => {
                                            closeModal(modalRef4)
                                            openModal(modalRef1)
                                        }}
                                        aria-label="Close"></button>
                                </div>
                                <div className="modal-body" >

                                    <p className="fs-5 text-danger">
                                        {t('acceptOffreText')}
                                    </p>
                                    <h6 className="fw-bold">{t('start')}</h6>
                                    <p>
                                        {t('startH2H')} {selectedOffre && selectedOffre.counterOffre.mam1 === true ? t('yes') : t('no')}
                                    </p>
                                    <p>
                                        {t('startRelai')} {(selectedOffre && selectedOffre.counterOffre.relai1) ? (counterRelai1) : (t('no'))}
                                    </p>
                                    <h6 className="fw-bold">{t('end')}</h6>
                                    <p>
                                        {t('endH2H')} {selectedOffre && selectedOffre.counterOffre.mam2 === true ? t('yes') : t('no')}
                                    </p>
                                    <p>
                                        {t('endRelai')} {(selectedOffre && selectedOffre.counterOffre.relai2) ? (counterRelai2) : (t('no'))}
                                    </p>
                                    <div> <b>{t('price')} : </b> {(selectedOffre && selectedOffre.counterOffre.prix) ? (selectedOffre.counterOffre.prix) : ((selectedOffre && selectedOffre.offre && selectedOffre.offre.prix) ? (selectedOffre.offre.prix) : (t('notFound')))}</div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => {
                                        closeModal(modalRef4)
                                        openModal(modalRef1)
                                    }}>{t("cancel")}</button>
                                    <button type="button" className="btn btn-outline-primary position-relative top-0 "
                                        onClick={() => {
                                            closeModal(modalRef4)

                                            accepter()
                                        }}
                                    >
                                        {t('accept')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="modal fade"
                        ref={modalRef5}
                        tabindex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"

                        role="dialog"
                        aria-labelledby="modalTitleId"
                        aria-hidden="true"
                    >
                        <div
                            class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md"
                            role="document"
                        >
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalTitleId">
                                        {t('validationCode')}
                                    </h5>
                                    <button
                                        type="button"
                                        class="btn-close"
                                        onClick={() => { closeModal(modalRef5) }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div class="modal-body">
                                    <p>
                                        {t('validationCodeText')}
                                    </p>
                                    <h1>
                                        {code}
                                    </h1>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        type="button"
                                        class="btn btn-outline-secondary w-auto"
                                        onClick={() => { closeModal(modalRef5) }}
                                    >
                                        {t("close")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Row>
    );
}
export default MesColis;
