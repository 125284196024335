import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react"
import { useMap } from "../../mapContext";

export default function Mapinput({ onChange, placeholder, firstValue, disabled }) {
    const [place, setPlace] = useState(null);
    const [validate, setValidate] = useState()
    const handlePlaceChanged = () => {

        if (place !== null) {
            try {
                const selectedPlace = place.getPlace();
                if (selectedPlace) {
                    setInputValue(selectedPlace.name);  // Set input value to the place name
                    setValidate(true);  // Mark the input as valid once a place is selected

                    // Extract the location and other details
                    const location = {
                        lat: selectedPlace.geometry.location.lat(),
                        lng: selectedPlace.geometry.location.lng(),
                    };
                    const components = selectedPlace.address_components;

                    // Send the selected place info to the parent
                    const newPlace = {
                        name: selectedPlace.name,
                        location,
                        address_components: components,
                    };
                    onChange(newPlace);
                }
            } catch (e) {
                console.error(e);
                setValidate(false);  // Keep it invalid in case of an error
            }
        }
    };

    const [inputValue, setInputValue] = useState(firstValue);

    useEffect(() => {
        setInputValue(firstValue)
    }, [firstValue])
    const { isLoaded } = useMap()

    return isLoaded ? (
        <Autocomplete className="col-12 "
            style={{ backgroundColor: "white", zIndex: "2000" }}
            onLoad={autocomplete => setPlace(autocomplete)}
            onPlaceChanged={handlePlaceChanged}
        >
            <div>
                <input
                    type="text"
                    className={`form-control ${validate == true ? "is-valid" : validate == false ? "is-invalid" : ""}`}  // Add appropriate class for valid or invalid
                    placeholder={placeholder || "Saisir une adresse"}  // Fallback placeholder
                    value={inputValue}
                    disabled={disabled}
                    onChange={(e) => {
                        setValidate(null);  // Reset validation status
                        if (e.target.value !== "") {
                            onChange(null)
                            setValidate(false);
                        }  // Reset validation status
                        setInputValue(e.target.value)
                    }}  // Update input value
                    required
                />
                <div className="invalid-feedback">Veuillez saisir et séléctionner une adresse valide</div>
            </div>
        </Autocomplete >
    ) : (<>
        <input type="text" className="form-control" placeholder={placeholder || "Saisir une adresse"} value={firstValue} />
    </>)
}
export function Relaisinput({ onChange, placeholder, firstValue }) {
    const [place, setPlace] = useState(null);
    const handlePlaceChanged = () => {
        if (place !== null) {
            let newPlace = {}
            try {
                const selectedPlace = place.getPlace();
                setInputValue(newPlace.name);
                console.log(selectedPlace)
                let location = {
                    "lat": selectedPlace.geometry.location.lat(),
                    "lng": selectedPlace.geometry.location.lng()
                };
                let address = selectedPlace.formatted_address
                let name = selectedPlace.name
                let components = selectedPlace.address_components
                newPlace = {
                    "name": name,
                    "location": location,
                    "address_components": components,
                    "address": address
                };

            }
            catch (e) {
                console.error(e);
            }
            finally {
                onChange(newPlace)
            }
        }
    };
    const [inputValue, setInputValue] = useState(firstValue);

    useEffect(() => {
        setInputValue(firstValue)
    }, [firstValue])
    const { isLoaded } = useMap()

    return isLoaded ? (
        <Autocomplete className="col-12 "
            style={{ backgroundColor: "white", zIndex: "2000" }}
            onLoad={autocomplete => setPlace(autocomplete)}
            onPlaceChanged={handlePlaceChanged}
        >
            <input
                type="text"
                className="form-control"
                placeholder={placeholder || "Saisir une adresse"}
                value={inputValue}
                onChange={event => setInputValue(event.target.value)}
                required
            />
        </Autocomplete >
    ) : (<></>)
}