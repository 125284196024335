import axios from "axios";
import { useEffect, useState } from "react";
import classes from "./style.module.css";
import PaginationComponent from "../../components/paginations/pagination";
import fetchImage from "../../components/fetchImg";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
function VoirBlogs() {
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState()
    const fetchBlogs = async (page) => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/blogs?page=${page}&limit=12`;
            const blogResponse = await axios.get(url);
            const blogData = blogResponse.data;
            console.log(blogData)
            setBlogs(blogData)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchCount = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/blogs/count`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        fetchCount();
        fetchBlogs();
    }, []);
    const { t } = useTranslation();
    const handleShare = async (blog) => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: blog.card.title,
                    text: blog.card.description,
                    url: `${process.env.REACT_APP_SERVER}/blog/${blog._id}`,
                    files: [
                        new File(
                            [fetchImage(blog.card.image)], // File content (you might need to convert the URL to an actual file)
                            'image.jpg',
                            { type: 'image/jpeg' }
                        ),
                    ]
                });
                console.log('Content shared successfully!');
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            console.error('Web Share API is not supported in your browser.');
        }
    }
    return (
        <div>
            <h1 className="text-center m-4 fw-bold" style={{ color: "#1f406d" }}>{t('seeLastBlogs')}</h1>

            <div className="container row justify-ceontent-center mx-auto">
                {blogs.map((blog, index) => {
                    return (
                        <div className="col-12 col-md-6 col-lg-4 ">
                            <a className="nav-link" href={`/blog/${blog._id}`}>
                                <div className='card col-12 h-100 bg-transparent border-0' >
                                    <div className='card-body row'>
                                        <div className='col-12 '>
                                            <img src={fetchImage(blog.card.image)}
                                                alt="Selected" className={`${classes.img} rounded-4 align-self-center m-auto`} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <p style={{ color: "#1f406d" }} className="bolder fs-4">{blog.card.title}</p>
                                            <p>{blog.card.description}</p>
                                        </div>
                                    </div>
                                </div>

                            </a>
                            <button onClick={() => handleShare(blog)}>Share</button>
                        </div>

                    )
                })}
            </div>
            <PaginationComponent page={page} lastPage={lastPage} fetchData={fetchBlogs} setPage={setPage} />
        </div>
    );
}
export default VoirBlogs;