import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import PaginationComponent from "../../../components/paginations/pagination";
import ColisCollapse from "../../../components/colisCollapse/colisCollapse";
import style from "../style.module.css"
import { useTranslation } from "react-i18next";
function Colis() {
    const { t } = useTranslation()

    const [demandes, setDemandes] = useState([]);
    const [postules, setPostules] = useState([]);
    const [page1, setPage1] = useState(1)
    const [lastPage1, setLastPage1] = useState()
    const [page2, setPage2] = useState(1)
    const [lastPage2, setLastPage2] = useState()

    const [selectedOffre, setSelectedOffre] = useState(null)
    const [offre, setOffre] = useState(null);

    const handleOffreSelection = async (index) => {
        let offreSelection = structuredClone(demandes[index].offre);
        console.log(offreSelection)
        let n1, n2, n3, n4
        if (offreSelection.counterOffre.relai1) {
            n1 = await getRelai(offreSelection.counterOffre.relai1)
            offreSelection.counterOffre.relai1 = n1.address

        }
        if (offreSelection.counterOffre.relai2) {
            n2 = await getRelai(offreSelection.counterOffre.relai2)
            offreSelection.counterOffre.relai2 = n2.address
        }
        if (offreSelection.offre.relai1) {
            n3 = await getRelai(offreSelection.offre.relai1)
            offreSelection.offre.relai1 = n3.address

        }
        if (offreSelection.offre.relai2) {
            n4 = await getRelai(offreSelection.offre.relai2)
            offreSelection.offre.relai2 = n4.address

        }

        setSelectedOffre(offreSelection)
        setOffre(offreSelection)


        console.log(offreSelection)
    }
    const getRelai = async (id) => {
        try {
            let address
            await axios.get(`${process.env.REACT_APP_SERVER}/relais/${id}`).then((res) => {
                address = res.data
            }).catch((err) => {
                console.log(err)
            });
            return address
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const columns2 = t("ttrajetOffreCols", { returnObjects: true })
    const columns = t("trajetCols", { returnObjects: true });

    const fetchCount1 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/trajets/count`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage1(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchCount2 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/TtrajetOffres/count`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage2(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData1 = async (page) => {
        try {
            console.log("fetching data", page)
            const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/trajets/all?page=${page}`);
            const trajetData = trajetResponse.data;

            const promises = trajetData.map(async (TrajetItem) => {
                try {
                    const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                    TrajetItem.colis = colisResponse.data ?? [];

                    return TrajetItem;
                } catch (error) {
                    console.error("Error fetching colis and offres data:", error);
                    return null; // Returning null to indicate failure
                }
            });

            const updateddemandesData = await Promise.all(promises);
            const filtereddemandesData = updateddemandesData.filter(item => item !== null);
            setPostules(filtereddemandesData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchData2 = async (page) => {
        let offresData = []
        try {
            await axios.get(`${process.env.REACT_APP_SERVER}/TtrajetOffres/all?page=${page}`).then((res) => {
                offresData = res.data;
            })
            const promises = offresData.map(async (OffreItem) => {
                try {
                    let trajet = {}
                    await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/${OffreItem.ttrajet}`)
                        .then(async (res) => {
                            trajet = res.data
                            trajet.colis = []
                            trajet.offre = OffreItem
                            for (let coli of OffreItem.colis) {
                                await axios.get(`${process.env.REACT_APP_SERVER}/colis/${coli}`)
                                    .then((res) => {
                                        trajet.colis.push(res.data)
                                    })
                            }
                        });
                    return trajet;
                } catch (error) {
                    console.error("Error fetching colis and offres data:", error.message);
                    return null; // Returning null to indicate failure
                }
            });
            const updateddemandesData = await Promise.all(promises);
            const filtereddemandesData = updateddemandesData.filter(item => item !== null);
            setDemandes(filtereddemandesData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchData = async () => {
        await fetchCount1();
        await fetchCount2();
        await fetchData1(page1);
        await fetchData2(page2);
    }
    useEffect(() => {

        fetchData()
    }, []);
    async function removeRow2(index) {
        try {
            let response;
            if (demandes[index].transporteur) {
                const id = demandes[index].offre._id;

                // Delete the "TtrajetOffres" and handle errors
                try {

                    response = await axios.delete(`${process.env.REACT_APP_SERVER}/TtrajetOffres/${id}`);


                } catch (offreError) {
                    console.error(`Failed to delete TtrajetOffres with id ${id}:`, offreError);
                    return; // Exit early if there's an error with the main delete request
                }
            }

            // Check if the response is successful and update the state
            if (response && response.status === 200) {
                fetchData2(page2)
            } else {
                console.error('Failed to delete item:', response);
            }
        } catch (error) {
            console.error('Unexpected error while deleting item:', error);
        }
    }
    async function removeRow1(index) {
        try {

            const id = postules[index]._id;

            // Delete the "trajets" and handle errors
            try {
                const response = await axios.delete(`${process.env.REACT_APP_SERVER}/trajets/${id}`);

                // Check if the response is successful and update the state
                if (response.status === 200) {
                    fetchData1(page1)
                } else {
                    console.error('Failed to delete item:', response);
                }
            } catch (deleteError) {
                console.error(`Failed to delete trajet with id ${id}:`, deleteError);
            }
        } catch (error) {
            console.error('Unexpected error while deleting item:', error);
        }
    }
    return (
        <Row style={{ maxWidth: "100%" }}>
            <nav>
                <div className={`${style.navs} nav nav-tabs`} id="nav-tab" role="tablist">
                    <button className="nav-link active w-50" id="nav-colis-tab" data-bs-toggle="tab" data-bs-target="#nav-colis" type="button" role="tab" aria-controls="nav-colis" aria-selected="true"> <h5 className="text-start fw-lighter m-0">{t('posted')}</h5> </button>
                    <button className="nav-link w-50" id="nav-demande-tab" data-bs-toggle="tab" data-bs-target="#nav-demande" type="button" role="tab" aria-controls="nav-demande" aria-selected="false"><h5 className="text-start fw-lighter m-0">  {t('demanded')}</h5> </button>


                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-colis" role="tabpanel" aria-labelledby="nav-colis-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">{t('postedPackages')}</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion1">
                                    {postules.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                {columns.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0">
                                                        {column[1] === "arrive_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : t('notFound')
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : t('notFound')
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "prix" ? (
                                                            row.prix ? row.prix : t('notFound')
                                                        ) : column[1] === "etat" ? (
                                                            row.etat ? row.etat : t('notFound')
                                                        ) : column[1] === "operations" ? (
                                                            <button className="btn btn-sm btn-outline-secondary col-12">
                                                                Aucune
                                                            </button>
                                                        ) : (
                                                            row[column[1]] || t('notFound')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>

                        </CardBody>
                        <PaginationComponent page={page1} lastPage={lastPage1} setPage={setPage1} fetchData={fetchData1} />

                    </Card>

                </div>
                <div className="tab-pane fade" id="nav-demande" role="tabpanel" aria-labelledby="nav-demande-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">{t('demandPackages')}</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns2.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion2">
                                    {demandes.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr >
                                                {columns2.map(column => (
                                                    <td key={column[1]} className=" align-middle  border-bottom-0 pb-0">
                                                        {column[1] === "arrive_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : t('notFound')
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : t('notFound')
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.offre.prix ? row.offre.offre.prix : t('notFound')
                                                        ) : column[1] === "counteroffre.prix" ? (
                                                            row.offre?.counterOffre?.prix ? row.offre.counterOffre.prix : t('notFound')
                                                        ) : column[1] === "etat" ? (
                                                            row.offre.etat ? row.offre.etat : t('notFound')
                                                        ) : (
                                                            row[column[1]] || t('notFound')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns2.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                        <PaginationComponent page={page2} lastPage={lastPage2} setPage={setPage2} fetchData={fetchData2} />
                    </Card>
                    <div className="modal modal-xl fade " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" >
                            <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                                <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                                    <h1 className="modal-title fs-5 text-light" id="exampleModalLabel">{t('consultOffre')}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" >
                                    <div className="row">
                                        <div className="col ">
                                            <div className="card rounded-5 p-3 h-100" >


                                                <h5 className="card-title">{t('transportOffre')}</h5>
                                                <div className="card-body">
                                                    <h6 className="fw-bold">{t('start')}</h6>
                                                    <p>
                                                        {t('startH2H')} {selectedOffre && selectedOffre.counterOffre.mam1 === true ? t('yes') : t('no')}
                                                    </p>
                                                    <p>
                                                        {t('startRelai')} {(selectedOffre && selectedOffre.counterOffre.relai1) ? (selectedOffre.counterOffre.relai1) : (t('no'))}
                                                    </p>
                                                    <h6 className="fw-bold">{t('end')}</h6>
                                                    <p>
                                                        {t('endH2H')} {selectedOffre && selectedOffre.counterOffre.mam2 === true ? t('yes') : t('no')}
                                                    </p>
                                                    <p>
                                                        {t('endRelai')} {(selectedOffre && selectedOffre.counterOffre.relai2) ? (selectedOffre.counterOffre.relai2) : (t('no'))}
                                                    </p>
                                                    <div> <b>{t('price')} : </b> {(selectedOffre && selectedOffre.counterOffre.prix) ? (selectedOffre.counterOffre.prix) : (t('notFound'))}</div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card rounded-5 p-3 h-100" >
                                                <h5 className="card-title">{t('partOffre')}</h5>
                                                <div className="card-body">
                                                    <fieldset>
                                                        <h6 className="fw-bold">{t('start')}</h6>
                                                        <div className="d-flex">
                                                            <p className="me-4">{t('startH2H')}  {selectedOffre && selectedOffre.offre && selectedOffre.offre.mam1 ? t('yes') : t('no')}</p>


                                                        </div>
                                                        <div className="d-flex">
                                                            <p className="me-4">{t('startRelai')} {selectedOffre && selectedOffre.offre && selectedOffre.offre.relai1 ? selectedOffre.offre.relai1 : t('no')} </p>
                                                        </div>

                                                    </fieldset>

                                                    <fieldset>
                                                        <h6 className="fw-bold">{t('end')}</h6>

                                                        <div className="d-flex">
                                                            <p className="me-4">{t('endH2H')} {selectedOffre && selectedOffre.offre && selectedOffre.offre.mam2 ? t('yes') : t('no')}</p>

                                                        </div>

                                                        <div className="d-flex">
                                                            <p className="me-4">{t('endRelai')}{selectedOffre && selectedOffre.offre && selectedOffre.offre.relai2 ? selectedOffre.offre.relai2 : t('no')}</p>

                                                        </div>

                                                    </fieldset>
                                                    <fieldset>
                                                        <div> <b>{t('price')} : </b> {(selectedOffre && selectedOffre.offre.prix) ? (selectedOffre.offre.prix) : (t('notFound'))}</div>

                                                    </fieldset>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal"
                                        onClick={() => {
                                            setSelectedOffre(undefined)
                                        }}>{t("cancel")}</button>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Row>
    );
}

export default Colis;
