// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import fr from './locales/fr.json';

// Initialize i18next with language resources
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            fr: { translation: fr },
        },
        lng: localStorage.getItem('i18nextLng') || 'fr',
        fallbackLng: 'fr', // fallback language
        interpolation: {
            escapeValue: false,
        },
    });
i18n.on('languageChanged', (lng) => {
    localStorage.setItem('i18nextLng', lng);
});
export default i18n;
