import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import PaginationComponent from "../../../components/paginations/pagination";
import { useTranslation } from "react-i18next";

function Admins() {
    const { t } = useTranslation()

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState()
    const columns = t('userCols', { returnObjects: true });
    const fetchCount = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/users/admins/count`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData = async (page) => {
        try {
            page = page || 1;
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/users/admins?page=${page}`;
            const response = await axios.get(url);
            const data = response.data;
            console.log(data)


            const filteredusersData = data.filter(item => item !== null);
            console.log(filteredusersData)
            setUsers(filteredusersData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {

        fetchCount()
        fetchData();
    }, []);


    const [formData, setFormData] = React.useState({ email: '', password: '', role: 'admin' })
    const onSubmit = (e) => {
        e.preventDefault()
        if (!handlepswcheck())
            return;
        console.log(formData)
        axios.post(`${process.env.REACT_APP_SERVER}/users/register`, formData)

            .catch((error) => {
                if (error.response.status === 400)
                    console.log(error.response.status);
            })
    }
    const [psw, setPsw] = useState()
    const handlepswcheck = () => {
        if (psw !== formData.password) {
            return false
        }
        return true
    }
    const handleCheckboxChange = async (index) => {
        try {    // Find the index of the row with the given id
            const row = users[index]
            console.log(row)
            row.active = !row.active
            // Update the active state of the row
            // Update the state
            await axios.put(`${process.env.REACT_APP_SERVER}/users/${row._id}`, { active: row.active }).then((response) => {
                fetchData()
            })
        }
        catch (error) {
            console.error("Error updating data:", error);
        }
    }
    return (
        <Row style={{ maxWidth: "100%" }} >
            <div className="mb-3 mt-1 w-100 p-0">
                <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    {t('addAdmin')}
                </button>
                <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                        <div className="col">
                            <div className="p-5">

                                <form className="user" onSubmit={onSubmit}>
                                    <div className="row mb-3">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input className="form-control form-control-user" type="text" id="exampleFirstName" placeholder={t('firstName')} name="first_name"
                                                onChange={(e) => setFormData({ ...formData, prenom: e.target.value })} />
                                        </div>
                                        <div className="col-sm-6">
                                            <input className="form-control form-control-user" type="text" id="exampleLastName" placeholder={t('lastName')} name="last_name"
                                                onChange={(e) => setFormData({ ...formData, nom: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <input className="form-control form-control-user" type="email" id="exampleInputEmail" aria-describedby="emailHelp" placeholder={t('emailInputPlaceholder')} name="email"
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input className="form-control form-control-user" type="password" id="password" placeholder={t("passwordInputPlaceholder")} name="password"
                                                onChange={(e) => setFormData({ ...formData, password: e.target.value })} /></div>
                                        <div className="col-sm-6">
                                            <input className="form-control form-control-user" type="password" placeholder={t('confirmPasswordInputPlaceholder')} name="repeat_password"
                                                onChange={(e) => setPsw(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <button className="btn btn-outline-primary d-block btn-user w-100" type="submit">{t('createAccount')}</button>

                                        </div></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Card className="Card">
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">{t('adminsList')}</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr data-bs-toggle="collapse" data-bs-target={`#accordionContent`} aria-expanded="false" aria-controls={`accordionContent`}>
                                            {columns.map(column => (
                                                <td key={column[1]} className=" align-middle">
                                                    {column[1] === "verified" ? (
                                                        row[column[1]] ? "Verifié" : "N'est pas vérifié"
                                                    ) : column[1] === "date_creation" ? (
                                                        new Date(row[column[1]]).toLocaleDateString('fr-FR')
                                                    ) : column[1] === "operations" ? (
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id={`flexSwitchCheckChecked-${row._id}`}
                                                                checked={row.active}
                                                                onChange={() => handleCheckboxChange(index)}
                                                            />
                                                            <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${row.id}`}>
                                                                {row.active ? 'Activé' : 'Désactivé'}
                                                            </label>
                                                        </div>
                                                    ) : (
                                                        row[column[1]] || t('notFound')
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <PaginationComponent page={page} lastPage={lastPage} setPage={setPage} fetchData={fetchData} />

                </CardBody>
            </Card>
        </Row>
    );
}

export default Admins;
