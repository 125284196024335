import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Ajouter_colis from './pages/ajouter-colis/ajouter-colis';
import Home from "./pages/home/home"
import MyNavbar from './components/navbar/navbar';
import './App.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Connection from './pages/connection/connection';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import axios from 'axios';
import Dashboard from './pages/dashboard/dashboard';
import VoirOffre from './pages/voir-offre/voir_offre';
import Unauthorized from './pages/unauthorized/unauthorized';
import Creercompte from './pages/creer-compte/creer-compte';
import VerifyEmailToken from './pages/verify-email/verify-email';
import GererCompte from './pages/gerer-compte/gerer-compte';
import Suivi from './pages/dashboard/pages/suivi';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import ProposerTrajet from './pages/proposer-trajet/proposer-trajet';
import VoirColisDispo from './pages/voir-colis-disponible/voir_colis_disponible';
import Paiement from './pages/payement/payement';
import Footer from './components/footer/footer';
import VoirTrajetsDispo from './pages/voir_trajets-disponible/voir_trajets_disponible';
import VoirBlogs from './pages/voir-blogs/voir_blogs';
import VoirBlog from './pages/voir-blog/voir_blog';
import ScrollToTop from './components/scroll_top';
import ChatRow from './components/chat/chatRow';
import SuccessPayment from './pages/payement/success';
import PaymentFailed from './pages/payement/failed';
import Report from './pages/report/report';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LocationProvider } from './locationContext';
import { MapProvider } from './mapContext';
import ResetPssword from './pages/resetPassword/resetPassword';
import './i18n';
import { useTranslation } from 'react-i18next';
import RequireAuthAndRole from './components/requireAuthRole';
const App = () => {
  const { t, i18n } = useTranslation();

  const [auth, setAuth] = useState(useIsAuthenticated());
  const [notifications, setNotifications] = useState([]);
  const [chatsBalls, setChatsBalls] = useState([]);
  const [openedChats, setOpenedChats] = useState([]);
  const notificationIds = useRef(new Set());
  const signOut = useSignOut();
  const token = window.localStorage.getItem('_auth');
  axios.defaults.headers.common['Authorization'] = `${token}`;
  axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true';
  const authUser = useAuthUser();
  const [role, setRole] = useState(authUser ? authUser.userRole || "particulier" : "particulier");
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  }
  const handleSignOut = () => {
    setNotifications([])
    signOut();
    setAuth(false);
    setChatsBalls([]);
    setOpenedChats([]);
  };
  const fetchMessages = async () => {
    try {
      console.log("fetching chats");
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/chats`);
      setChatsBalls(res.data);
      console.log("finished fetching chats");
    } catch (err) {
      console.error(err);
    }
  }
  const handleSignIn = (newData) => {
    console.log('signIn :', newData);
    console.log('role :', newData.role);
    setAuth(newData.auth);
    setRole(newData.role);
    fetchMessages();
  };
  const updateNotification = async (notification) => {
    try {
      if (!notification.read) {
        await axios.patch(`${process.env.REACT_APP_SERVER}/notifications/${notification._id}`)
          .then((res) => {
            console.log(res);
            notification = res.data;
          })
          .catch((err) => {
          });
        const updatedNotifications = notifications.map(notif => notif._id === notification._id ? notification : notif);
        setNotifications(updatedNotifications);
      }
    } catch (err) {
    }
  };
  useEffect(() => {
    if (auth) {
      fetchMessages();
      const eventSource = new EventSource(`${process.env.REACT_APP_SERVER}/notifications?token=${window.localStorage.getItem('_auth')}`);
      eventSource.onmessage = (event) => {
        const notification = JSON.parse(event.data);
        if (!notificationIds.current.has(notification._id)) {
          setNotifications(prevNotifications => [...prevNotifications, notification]);
          notificationIds.current.add(notification._id);
        }
      };
      eventSource.onerror = (error) => {
      };
    }
  }, [auth]);
  const openChat = (id, type) => {
    console.log('open trajet chat ')
    console.log("opened chats", openedChats);
    const chat = chatsBalls.filter(chat => chat.offreId === id && chat.offreType === type);
    console.log(openedChats.includes(chat[0]));
    if (openedChats.includes(chat[0])) {
      console.log('Chat with the specified offreId and offreType exists');
    } else {
      console.log('No chat with the specified offreId and offreType found');
      setOpenedChats(prevChats => [...prevChats, chat[0]]);
    }
  }


  return (
    <HelmetProvider>
      <Helmet>
        <title>Colispo</title>
        <link rel="canonical" href="https://www.colispo.com/" />
        <meta name="description" content="Colispo est une plateforme de mise en relation entre particuliers et transporteurs pour l'envoi de colis." />
        <meta name="keywords" content={`${process.env.REACT_APP_KEYWORDS}`} />
      </Helmet>

      <ChatRow openedChats={openedChats} setOpenedChats={setOpenedChats} chatsBalls={chatsBalls} setChatsBalls={setChatsBalls} />
      <MapProvider>
        <LocationProvider>
          <Router>
            <ScrollToTop />
            <div className='min-vh-100'>
              <div className='sticky-top'>
                <MyNavbar handleLanguageChange={handleLanguageChange} role={role} isAuthenticated={auth} handleSignOut={handleSignOut} notifications={notifications} setNotifications={setNotifications} updateNotification={updateNotification} />
              </div>
              <div className="platformContent">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/blogs" element={<VoirBlogs />} />
                  <Route path="/blog/:id" element={<VoirBlog />} />
                  <Route path="/connection" element={<Connection SetIsAuthenticated={handleSignIn} />} />
                  <Route path="/inscription" element={<Creercompte SetIsAuthenticated={handleSignIn} />} />
                  <Route path="/connection" element={<Connection SetIsAuthenticated={handleSignIn} />} />
                  <Route path="report" element={<RequireAuthAndRole roles={['particulier']}><Report /></RequireAuthAndRole>} />
                  <Route path="/ajouterColis" element={<RequireAuthAndRole roles={['particulier']}><Ajouter_colis /></RequireAuthAndRole>} />
                  <Route path="/voirColis" element={<VoirColisDispo />} />
                  <Route path="/dashboard/*" element={<RequireAuthAndRole roles={['admin', 'particulier', 'superadmin', 'transporteur']}><Dashboard openChat={openChat} /></RequireAuthAndRole>} />
                  <Route path="/voirOffre" element={<RequireAuthAndRole roles={['particulier', 'transporteur']}><VoirOffre /></RequireAuthAndRole>} />
                  <Route path="/proposerTrajet" element={<RequireAuthAndRole roles={['particulier', 'transporteur']}><ProposerTrajet /></RequireAuthAndRole>} />
                  <Route path='unauthorized' element={<Unauthorized />} />
                  <Route path='verify-email' element={<VerifyEmailToken />} />
                  <Route path='success' element={<RequireAuthAndRole roles={['particulier']}><SuccessPayment /></RequireAuthAndRole>} />
                  <Route path='resetPassword' element={<ResetPssword />} />
                  <Route path='cancel' element={<RequireAuthAndRole roles={['particulier']}><PaymentFailed /></RequireAuthAndRole>} />
                  <Route path='payement' element={<RequireAuthAndRole roles={["particulier"]}><Paiement /> </RequireAuthAndRole>} />
                  <Route path='/voirTrajets' element={<VoirTrajetsDispo />} />
                  <Route path='gererCompte' element={<RequireAuthAndRole roles={['transporteur', 'particulier']}><GererCompte handleSignOut={handleSignOut} /></RequireAuthAndRole>} />
                  <Route path='suivi' element={<RequireAuthAndRole roles={['admin', 'particulier']}><Suivi /></RequireAuthAndRole>} />
                </Routes>
              </div>

            </div>
            <Footer />
          </Router >
        </LocationProvider>
      </MapProvider>
    </HelmetProvider>

  );
};

export default App;
