import { useEffect, useRef, useState } from "react";
import classes from "./ajouter-colis.module.css"
import { useTranslation } from "react-i18next";
function Ajouter_colis_form({ coli, index, setColis }) {
    const { t } = useTranslation()
    const [imageFiles, setImageFiles] = useState([]);
    const weightList = t('weightList', { returnObjects: true });
    const fileInputRef = useRef(null)
    const [fileSizeError, setFileSizeError] = useState(false)
    const handleFileInputChange = async (event) => {

        const files = event.target.files;

        if (files.length > 0) {
            const newImageFiles = Array.from(files).map(file => ({
                file,
                imageUrl: URL.createObjectURL(file)
            }));

            const updatedImageFiles = [...imageFiles, ...newImageFiles];
            if (!verifyFilesSize(updatedImageFiles)) {
                setFileSizeError(true);
                return;
            }
            setFileSizeError(false);
            setImageFiles(updatedImageFiles);
            await setColis(prevColis => prevColis.map((coli, i) => i === index ? { ...coli, photos: updatedImageFiles } : coli));
        }

    };
    const verifyFilesSize = (imageFiles) => {
        for (let file of imageFiles) {
            if ((file.file.size / (1024 * 1024)).toFixed(2) > 5)
                return false
        }
        return true
    }
    const handleInputChange = (event) => {
        let { name, value } = event.target;
        name = name.split("-")[0];
        if (name !== "title")
            value = Number(value);
        setColis(prevColis => prevColis.map((coli, i) => i === index ? { ...coli, [name]: value } : coli));
    }
    const handleRemoveImage = async (imageIndex) => {
        const updatedFiles = imageFiles.filter((_, index) => index !== imageIndex);
        setImageFiles(updatedFiles);
        if (updatedFiles.length === 0 && fileInputRef.current) {
            fileInputRef.current.value = null;
        }
        await setColis(prevColis => prevColis.map((coli, i) => i === index ? { ...coli, photos: updatedFiles } : coli));
    };
    useEffect(() => {
        if (!coli) {
            setImageFiles([]);
            coli = {}
        }
        console.log(coli)
    }, [coli]);
    if (!coli) {
        return;
    }

    return (
        <div className={`${classes.FromBox} `}>
            <div>
                <div className="row align-items-top">
                    <div className="col-3">
                        <label htmlFor="" className="mb-auto">{t('qte')}</label>
                        <input required name="qte" min="1" className={`form-control `} type="number" value={coli.qte || ''}
                            onChange={handleInputChange} />
                        <div class="invalid-feedback">Veuillez entrer une quantité valide.</div>
                    </div>
                    <div className="col">
                        <label htmlFor="" className="mb-auto">{t("objectName")}</label>
                        <input required name="title" className={`form-control  `} type="text" value={coli.title || ''}
                            onChange={handleInputChange} />
                        <div class="invalid-feedback">Veuillez spécifier le nom de l'objet</div>
                    </div>
                </div>
                <div className="row mt-2 ">
                    <div className="col ">
                        <label htmlFor="" className="mb-auto">{t('length')} (cm)</label>
                        <input required name="length" className={`form-control`} min="1" type="number" value={coli.length || ''}
                            onChange={handleInputChange} />
                        <div class="invalid-feedback">Veuillez entrer la longueur </div>
                    </div>
                    <div className="col ">
                        <label htmlFor="" className="mb-auto">{t('width')} (cm)</label>
                        <input required name="width" className={`form-control  `} min="1" type="number" value={coli.width || ''}
                            onChange={handleInputChange} />
                        <div class="invalid-feedback">Veuillez entrer la largeur </div>
                    </div>
                    <div className=" col ">
                        <label htmlFor="" className="mb-auto">{t('height')} (cm)</label>
                        <input required name="height" className={`form-control  `} min="1" type="number" value={coli.height || ''}
                            onChange={handleInputChange}
                        />
                        <div class="invalid-feedback">Veuillez entrer l'hauteur </div>
                    </div>
                </div>

                <div className="row  mt-2">
                    <label> {t('weight')}</label>
                    <div className={`row m-0`}>
                        <input
                            className={`btn-check col-12 col-sm`}
                            type="radio"
                            required
                            name={`weight-${index}`}
                            value="1"
                            id={`-5kG-${index}`}
                            checked={coli.weight === 1}
                            onChange={handleInputChange} />
                        <label
                            className={`btn btn-outline-primary col-12 col-sm`}
                            htmlFor={`-5kG-${index}`}
                        >
                            {weightList[0]}
                        </label>

                        <input
                            className={`btn-check col-12 col-sm`}
                            type="radio"
                            name={`weight-${index}`} value="2"
                            checked={coli.weight === 2}
                            required
                            id={`5KG à 30KG-${index}`}
                            onChange={handleInputChange}
                        />
                        <label
                            className={`btn btn-outline-primary col-12 col-sm`}
                            htmlFor={`5KG à 30KG-${index}`}
                        >
                            {weightList[1]}
                        </label>

                        <input
                            className={`btn-check col-12 col-sm`}
                            type="radio"
                            name={`weight-${index}`} value="3"
                            checked={coli.weight === 3}
                            required
                            id={`30KG à 50KG-${index}`}
                            onChange={handleInputChange} />
                        <label
                            className={`btn btn-outline-primary col-12 col-sm`}
                            htmlFor={`30KG à 50KG-${index}`}
                        >
                            {weightList[2]}
                        </label>

                        <input
                            className={`btn-check col-12 col-sm`}
                            type="radio"
                            name={`weight-${index}`}
                            value="4"
                            checked={coli.weight === 4}
                            required
                            id={`50KG à 100KG-${index}`}
                            onChange={handleInputChange}
                        />
                        <label
                            className={`btn btn-outline-primary col-12 col-sm`}
                            htmlFor={`50KG à 100KG-${index}`}
                        >
                            {weightList[3]}
                        </label>

                        <input
                            className={`btn-check col-12 col-sm`}
                            type="radio"
                            name={`weight-${index}`}
                            value="5"
                            checked={coli.weight === 5}
                            required
                            id={`100KG-${index}`}
                            onChange={handleInputChange} />
                        <label
                            className={`btn btn-outline-primary col-12 col-sm`}
                            htmlFor={`100KG-${index}`}
                        >
                            <p>{weightList[4]}</p>
                        </label>
                        <div className="invalid-feedback col-12">
                            {t('weightError')}
                        </div>
                    </div>
                </div>



                <div className="row mt-2">
                    <div>
                        <label>{t('photo')}</label>

                        <div className={`${classes.file_card} p-1 align-items-start`}>
                            <input id={`photo-${index}`} type="file" ref={fileInputRef} className={`d-none ${fileSizeError ? "is-invalid" : ""}`} onChange={handleFileInputChange} multiple required />
                            <label htmlFor={`photo-${index}`} className='row'>
                                < div className={`${classes.file_inputs} col-2 d-flex justify-content-center align-items-center`}>
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 50 50"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.77086 38.0209C6.02086 42.3126 8.33336 45.8334 14.0834 45.8334H35.9167C41.6667 45.8334 43.9584 42.3126 44.2292 38.0209L45.3125 20.8126C45.6042 16.3126 42.0209 12.5001 37.5 12.5001C36.2292 12.5001 35.0625 11.7709 34.4792 10.6459L32.9792 7.62508C32.0209 5.72925 29.5209 4.16675 27.3959 4.16675H22.625C20.4792 4.16675 17.9792 5.72925 17.0209 7.62508L15.5209 10.6459C14.9375 11.7709 13.7709 12.5001 12.5 12.5001C7.97919 12.5001 4.39586 16.3126 4.68752 20.8126L5.22919 29.2917"
                                            stroke="black"
                                            strokeOpacity="0.88"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M21.875 16.6667H28.125"
                                            stroke="black"
                                            strokeOpacity="0.88"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M25.0003 37.4999C28.7295 37.4999 31.7712 34.4583 31.7712 30.7291C31.7712 26.9999 28.7295 23.9583 25.0003 23.9583C21.2712 23.9583 18.2295 26.9999 18.2295 30.7291C18.2295 34.4583 21.2712 37.4999 25.0003 37.4999Z"
                                            stroke="black"
                                            strokeOpacity="0.88"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className='col'>
                                    <p className={`${classes.cliquez}`}>{t('photoInputPlaceholder')}</p>
                                    <p className={`${classes.JPG}`} >
                                        {t('photoInputText')}
                                    </p>
                                </div >
                            </label>
                            {!fileSizeError ? <div class="invalid-feedback">Veuillez séléctionner un photo</div> :
                                <div class="invalid-feedback">Veuillez séléctionner un photo de taille maximale 5 MB</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='d-flex flex-wrap mt-2'>
                        {coli.photos.map((image, imageIndex) => (
                            <div key={imageIndex} className='position-relative m-2'>
                                <img src={image.imageUrl} alt="Uploaded" className='rounded-3' style={{ maxWidth: '100px', marginRight: '10px' }} />
                                <span onClick={() => handleRemoveImage(imageIndex)} className="position-absolute top-0 start-100 translate-middle ">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="black" fillOpacity="0.88" />
                                        <path d="M13.0604 12.0004L15.3604 9.70035C15.6504 9.41035 15.6504 8.93035 15.3604 8.64035C15.0704 8.35035 14.5904 8.35035 14.3004 8.64035L12.0004 10.9404L9.70035 8.64035C9.41035 8.35035 8.93035 8.35035 8.64035 8.64035C8.35035 8.93035 8.35035 9.41035 8.64035 9.70035L10.9404 12.0004L8.64035 14.3004C8.35035 14.5904 8.35035 15.0704 8.64035 15.3604C8.79035 15.5104 8.98035 15.5804 9.17035 15.5804C9.36035 15.5804 9.55035 15.5104 9.70035 15.3604L12.0004 13.0604L14.3004 15.3604C14.4504 15.5104 14.6404 15.5804 14.8304 15.5804C15.0204 15.5804 15.2104 15.5104 15.3604 15.3604C15.6504 15.0704 15.6504 14.5904 15.3604 14.3004L13.0604 12.0004Z" fill="black" fillOpacity="0.88" />
                                    </svg>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>

    )
}
export default Ajouter_colis_form;