import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './chat.module.css';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.min';
import fetchImage from '../fetchImg';

function ChatBox({ chat, setChats, setSelectedChat }) {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [show, setShow] = useState(true);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const token = window.localStorage.getItem("_auth");
    var messageIds = useRef(new Set());
    const hideChat = () => setShow(false);
    const showChat = () => setShow(true);
    const chatMessagesRef = useRef(null); // Ref for chatMessages container
    const [chatsLength, setChatsLength] = useState(0);
    useEffect(() => {
        if (chatMessagesRef.current) {
            // Scroll to bottom when new messages are received
            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
        }
    }, [messages]); // Trigger when `messages` array changes

    useEffect(() => {
        setMessages([]);
        messageIds.current = new Set();
    }, [chat]);
    const closeChat = () => {
        setChats((prevChats) => prevChats.filter((c) => c._id !== chat._id));
    }
    const fetchData = (page) => {
        const eventSource = new EventSource(
            `${process.env.REACT_APP_SERVER}/messages/${chat._id}?token=${token}&page=${page}&offset=${offset}`
        );

        eventSource.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (Array.isArray(data)) {
                    // Handle paginated messages
                    setChatsLength(data.length);
                    const newMessages = data.filter((message) => !messageIds.current.has(message._id));
                    if (newMessages.length > 0 && page === 1) {
                        setMessages((prevMessages) => [...prevMessages, ...newMessages]);
                        newMessages.forEach((message) => messageIds.current.add(message._id));
                    }
                    else if (newMessages.length > 0) {
                        setMessages((prevMessages) => [...newMessages, ...prevMessages]);
                        newMessages.forEach((message) => messageIds.current.add(message._id));
                    }
                } else if (data && data._id) {
                    // Handle single new message
                    setOffset((prevOffset) => prevOffset + 1);
                    if (!messageIds.current.has(data._id)) {
                        setMessages((prevMessages) => [...prevMessages, data]);
                        messageIds.current.add(data._id);
                    }
                }
            } catch (error) {
                console.error("Error parsing message data:", error);
            }
        };

        eventSource.onerror = (error) => {
            console.error("EventSource failed:", error);
            eventSource.close(); // Close the EventSource on error
        };

        // Return cleanup function
        return () => {
            eventSource.close();
        };
    };


    const loadMore = async (page) => {
        setPage(page);

        // Wait for messages to update, then scroll to top
        setTimeout(() => {
            if (chatMessagesRef.current) {
                chatMessagesRef.current.scrollTop = 0;
            }
        }, 100); // Add a small delay to ensure React rendering completes
    };


    useEffect(() => {
        if (!chat || !token) return; // Skip if chat or token is not available

        const cleanup = fetchData(page);
        return cleanup; // Cleanup EventSource on unmount or dependency change
    }, [chat, token, page]); // Dependency list includes `chat`, `token`, and `page`
    const handleSendMessage = (event) => {
        event.preventDefault();
        axios.post(`${process.env.REACT_APP_SERVER}/messages`, { chatId: chat._id, text: newMessage })
            .then(() => setNewMessage(''))
            .catch(error => console.error('Error sending message:', error));
    };

    const getUserProfilePic = (chat) => {
        const user = chat.users.find((user) => user._id !== chat.receiver._id);
        return user ? user.profilePic : 'users/default.png';
    };
    const lastMessageRef = useRef(null);
    const getRelai = async (id) => {
        try {
            let address
            await axios.get(`${process.env.REACT_APP_SERVER}/relais/${id}`).then((res) => {
                address = res.data
            }).catch((err) => {
                console.log(err)
            });
            return address
        } catch (err) {
            console.log(err)
            return null
        }
    }
    const handleSelection = async () => {
        if (chat) {
            if (chat.offreType === "TtrajetOffre") {
                if (chat.offre.offre.relai1 && !chat.offre.offre.relai1._id) {
                    chat.offre.offre.relai1 = await getRelai(chat.offre.offre.relai1);
                }
                if (chat.offre.offre.relai2 && !chat.offre.offre.relai2._id) {
                    chat.offre.offre.relai2 = await getRelai(chat.offre.offre.relai2);
                }
                if (chat.offre.counterOffre.relai1 && !chat.offre.counterOffre.relai1._id) {
                    chat.offre.counterOffre.relai1 = await getRelai(chat.offre.counterOffre.relai1);
                }
                if (chat.offre.counterOffre.relai2 && !chat.offre.counterOffre.relai2._id) {
                    chat.offre.counterOffre.relai2 = await getRelai(chat.offre.counterOffre.relai2);
                }

            }
            setSelectedChat(chat);
        }
    };




    useEffect(() => {
        // Scroll to the last message when messages change
        console.log("messages changed")
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        // Initialize Bootstrap tooltips
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, [messages]);
    let previousDate = null;

    return (
        <div className={styles.chatContainer}>
            <div className={`card ${styles.chatCard}`} id="chat1">
                {/* Chat Header */}
                {show ? (
                    <div className={`${styles.chatHeader} d-flex justify-content-between align-items-center`}>
                        <p className={`${styles.chatUser} mb-0 fw-bold`}>{`${chat.receiver.nom} ${chat.receiver.prenom}`}</p>

                        {/* Middle div takes available space */}
                        <div className={`${styles.chatHeaderLink}  link-light`} onClick={handleSelection}  >
                            <div className='d-flex justify-content-center align-items-center  '>
                                <p className='m-0 me-2'>{chat.trajet.from_address.name}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                </svg>
                                <p className='m-0 ms-2'>{chat.trajet.to_address.name}</p>
                            </div>
                        </div>


                        {/* Right side buttons */}
                        <div className='d-flex'>
                            <button className={`${styles.btnCloseChat} btn me-4`} onClick={hideChat}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8" />
                                </svg>
                            </button>
                            <button className={`${styles.btnCloseChat} btn`} onClick={closeChat}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className={`${styles.chatHeader} p-3 d-flex`} onClick={showChat}>
                        <p className={`${styles.chatUser} mb-0 fw-bold`}>{`${chat.receiver.nom} ${chat.receiver.prenom}`}</p>
                        <div className={`${styles.chatHeaderLink} ms-4`} onClick={handleSelection}>
                            <div className='d-flex justify-content-center align-items-center  '>
                                <p className='m-0 me-2'>{chat.trajet.from_address.name}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                </svg>
                                <p className='m-0 ms-2'>{chat.trajet.to_address.name}</p>
                            </div>
                        </div>


                    </div>
                )}
                {/* Chat Body */}
                {show && (
                    <div className={`card-body ${styles.chatBody}`}>
                        <div ref={chatMessagesRef} className={styles.chatMessages}>
                            {chatsLength === 10 && (<a className='link-secondary text-center' style={{ cursor: "pointer" }} onClick={() => { loadMore(page + 1) }}> Charger plus</a >)}
                            {messages.map((message, index) => {
                                const messageDate = new Date(message.timestamp).toLocaleDateString();
                                const isDifferentDay = messageDate !== previousDate;
                                previousDate = messageDate;
                                return (
                                    <div>

                                        {/* Display the date if it's a new day */}
                                        {isDifferentDay && (
                                            <p className="small text-center text-muted mb-1">
                                                {new Date(message.timestamp).toLocaleDateString([], { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}
                                            </p>
                                        )}

                                        <div key={index} className={`d-flex ${message.sender ? 'justify-content-end' : 'justify-content-start'} mb-3`} ref={index === messages.length - 1 ? lastMessageRef : null}>

                                            {message.sender ? (
                                                <div className={`${styles.senderMessage} d-flex flex-row align-items-center justify-content-end`}>
                                                    <div className={`${styles.messageBubble} ${styles.senderBubble} `}>
                                                        <p className="small mb-0" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title={new Date(message.timestamp).toLocaleString([], { hour: '2-digit', minute: '2-digit' })}>{message.content}</p>
                                                    </div>
                                                    <img
                                                        src={fetchImage(getUserProfilePic(chat))}
                                                        className={styles.chatAvatar}
                                                        alt="Sender avatar"
                                                    />
                                                </div>
                                            ) : (
                                                <div className={`${styles.receiverMessage} d-flex flex-row align-items-center justify-content-start`}>
                                                    <img
                                                        src={fetchImage(chat.receiver.profilePic)}
                                                        className={styles.chatAvatar}
                                                        alt="Receiver avatar"
                                                    />
                                                    <div className={`${styles.messageBubble} ${styles.receiverBubble}`}>
                                                        <p className="small mb-0" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title={new Date(message.timestamp).toLocaleString([], { hour: '2-digit', minute: '2-digit' })}>{message.content}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {Date(message.timestamp).toLocaleString() > Date.now() && <p className="small text-muted">{new Date(message.timestamp).toLocaleString([], { hour: '2-digit', minute: '2-digit' })}</p>}
                                        </div>
                                    </div>

                                )
                            })}
                        </div>
                        {/* Message Input */}
                        <form onSubmit={handleSendMessage} className={styles.chatInputForm}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Saisir message"
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                />
                                <button className={`${styles.sendButton} btn`} type="submit">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ChatBox;
