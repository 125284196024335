import { useEffect, useRef, useState } from 'react';
import fetchImage from '../../fetchImg';
import classes from './coli.module.css'
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import { Carousel } from "bootstrap/dist/js/bootstrap.min.js";
import { useTranslation } from 'react-i18next';

export default function ColisCard({ colis }) {
    const modalRef = useRef(null);
    const carouselRef = useRef(null);
    const [carouselInstance, setCarrouselInstance] = useState(null);
    const openModal = (ref, coli) => {
        setColi(coli)
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const [coli, setColi] = useState(null)
    useEffect(() => {
        const carouselElement = carouselRef.current;
        const carouselInstance = new Carousel(carouselElement);
        setCarrouselInstance(carouselInstance)

    }, [carouselRef]);
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    };
    const { t } = useTranslation();
    const weightList = t('weightList', { returnObjects: true });
    return (
        <div className={`${classes.card} card mb-3 shadow overflow-auto w-100`} style={{ maxWidth: "550PX" }} >
            <table className="table  m-0 align-middle">
                <tbody>
                    {colis.map((coli, index) => (
                        <tr>
                            <td className="text-start" ><p style={{ fontSize: "16px", margin: "0" }}>{coli.qte}</p></td>
                            <td>
                                <div type="button" onClick={() => openModal(modalRef, coli)} >
                                    <img src={fetchImage(coli.photos[0])} className="img-fluid rounded-start" style={{ maxHeight: "70px" }} alt="..." />
                                </div>
                            </td>
                            <td className="text-start text-capitalize" ><p style={{ fontSize: "16px", margin: "0" }}>{coli.title}</p></td>

                            <td className="text-start" ><p style={{ fontSize: "16px", margin: "0" }}>{t("dim")}: {coli.length} x {coli.width} x {coli.height} cm</p>
                                <p style={{ fontSize: "16px", margin: "0" }}>{t('weight')}: {weightList[coli.weight - 1]}</p></td>
                        </tr>
                    ))}


                </tbody>
            </table>

            <div className="modal modal-xl fade " ref={modalRef} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Photos</h1>
                            <button type="button" className="btn-close" onClick={() => closeModal(modalRef)} aria-label="Close"></button>
                        </div>
                        <div ref={carouselRef} className="carousel slide">

                            <div className="carousel-inner">
                                {coli ? coli.photos.map((photo, index) => (
                                    <div
                                        key={index}
                                        className={`carousel-item ${index === 0 ? "active" : ""}`}
                                    >
                                        <img
                                            src={fetchImage(photo)}
                                            className="d-block w-100"
                                            style={{ maxHeight: "80vh", objectFit: "contain" }}
                                            alt={`Slide ${index + 1}`}
                                        />
                                    </div>
                                )) : null}
                            </div>
                            <button
                                className="carousel-control-prev"
                                type="button"
                                onClick={() => {
                                    carouselInstance.prev();
                                }}
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                                className="carousel-control-next"
                                type="button"
                                onClick={() => {
                                    carouselInstance.next();
                                }}
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </div >
    )

}