import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "../../pages/ajouter-colis/ajouter-colis.module.css"
const CameraComponent = ({ handleChange }) => {
    const [image, setImage] = useState(null);
    const verifyFilesSize = (file) => {
        if (!file || !file.size) {
            return false;
        }
        if ((file.size / (1024 * 1024)) > 5) {
            return false;
        }
        return true;
    };
    const [error, setError] = useState(false)
    const [error1, setError1] = useState(false)
    const handleCapture = (event) => {
        setError(false)
        setError1(false)
        const file = event.target.files[0];
        if (file) {
            if (!verifyFilesSize(file)) {
                setError1(true)
                return
            }
            const imageUrl = URL.createObjectURL(file);
            setImage(imageUrl);
            handleChange(file);
        }
        else if (!image)
            setError(true)
    };
    const { t } = useTranslation()

    return (
        <div style={{ textAlign: "center" }}>
            <div className="mt-2">
                <div className={`${classes.file_card}  align-items-start`}>
                    <input
                        className={`d-none ${error || error1 ? 'is-invalid' : ''}`}
                        id="photo"
                        type="file"
                        accept="image/*"
                        capture="user"
                        onChange={handleCapture}
                    />
                    <label htmlFor={`photo`} className='row'>
                        < div className={`${classes.file_inputs} col-2 d-flex justify-content-center align-items-center`}>
                            <svg
                                width="40"
                                height="40"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.77086 38.0209C6.02086 42.3126 8.33336 45.8334 14.0834 45.8334H35.9167C41.6667 45.8334 43.9584 42.3126 44.2292 38.0209L45.3125 20.8126C45.6042 16.3126 42.0209 12.5001 37.5 12.5001C36.2292 12.5001 35.0625 11.7709 34.4792 10.6459L32.9792 7.62508C32.0209 5.72925 29.5209 4.16675 27.3959 4.16675H22.625C20.4792 4.16675 17.9792 5.72925 17.0209 7.62508L15.5209 10.6459C14.9375 11.7709 13.7709 12.5001 12.5 12.5001C7.97919 12.5001 4.39586 16.3126 4.68752 20.8126L5.22919 29.2917"
                                    stroke="black"
                                    strokeOpacity="0.88"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M21.875 16.6667H28.125"
                                    stroke="black"
                                    strokeOpacity="0.88"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M25.0003 37.4999C28.7295 37.4999 31.7712 34.4583 31.7712 30.7291C31.7712 26.9999 28.7295 23.9583 25.0003 23.9583C21.2712 23.9583 18.2295 26.9999 18.2295 30.7291C18.2295 34.4583 21.2712 37.4999 25.0003 37.4999Z"
                                    stroke="black"
                                    strokeOpacity="0.88"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                        <div className='col'>
                            <p className={`${classes.cliquez} text-start`}>{t('photoInputPlaceholder')}</p>
                            <p className={`${classes.JPG} text-start mb-0`} >
                                {t('photoInputText')}
                            </p>
                        </div>
                    </label>
                    {error && <div class="invalid-feedback">Veuillez séléctionner un photo</div>}
                    {error1 && <div class="invalid-feedback">Veuillez séléctionner un photo de taille maximale 5 MB</div>}
                </div>
                {image &&
                    <img src={image} alt="Captured" style={{ maxWidth: "100%", height: "auto" }} />
                }  </div>

        </div>
    );
};

export default CameraComponent;
