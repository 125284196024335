import { useNavigate } from 'react-router-dom';
import classes from './trajetCard.module.css';
import fetchImage from '../../fetchImg';
import { useTranslation } from 'react-i18next';
function TrajetCard({ trajet }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const weightList = t("weightList", { returnObjects: true })
    return (
        trajet.colis.map((coli) => (
            <div className="col d-flex justify-content-center">
                <div className={`${classes.card} card mb-3 shadow border border-0 overflow-auto w-100`} style={{ maxWidth: "550PX" }}>
                    <div className="row g-0">
                        <div className="d-none col-2 col-md-3 d-sm-flex align-items-center p-2" onClick={() => { navigate(`/voirOffre`, { state: trajet }) }}>
                            <img src={fetchImage(coli.photos[0])} className="img-fluid rounded-start" style={{ maxHeight: "160px", maxWidth: "100%" }} alt="..." />
                        </div>
                        <div className="col-8 col-sm-6" onClick={() => { navigate(`/voirOffre`, { state: trajet }) }}>
                            <div className="card-body ">
                                <p className="card-title text-capitalize fw-bold" style={{ color: "#1f406d", fontSize: "22px" }}>{coli.title}</p>
                                <div className='d-flex'>
                                    <div className='d-flex flex-column justify-content-center' style={{ width: "20px" }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0004 11.8084C8.22539 11.8084 6.77539 10.3667 6.77539 8.58337C6.77539 6.80003 8.22539 5.3667 10.0004 5.3667C11.7754 5.3667 13.2254 6.80837 13.2254 8.5917C13.2254 10.375 11.7754 11.8084 10.0004 11.8084ZM10.0004 6.6167C8.91706 6.6167 8.02539 7.50003 8.02539 8.5917C8.02539 9.68337 8.90872 10.5667 10.0004 10.5667C11.0921 10.5667 11.9754 9.68337 11.9754 8.5917C11.9754 7.50003 11.0837 6.6167 10.0004 6.6167Z" fill="black" fillOpacity="0.4" />
                                            <path d="M10.0004 18.9666C8.76706 18.9666 7.52539 18.5 6.55872 17.575C4.10039 15.2083 1.38372 11.4333 2.40872 6.94163C3.33372 2.86663 6.89206 1.04163 10.0004 1.04163C10.0004 1.04163 10.0004 1.04163 10.0087 1.04163C13.1171 1.04163 16.6754 2.86663 17.6004 6.94996C18.6171 11.4416 15.9004 15.2083 13.4421 17.575C12.4754 18.5 11.2337 18.9666 10.0004 18.9666ZM10.0004 2.29163C7.57539 2.29163 4.45872 3.58329 3.63372 7.21663C2.73372 11.1416 5.20039 14.525 7.43372 16.6666C8.87539 18.0583 11.1337 18.0583 12.5754 16.6666C14.8004 14.525 17.2671 11.1416 16.3837 7.21663C15.5504 3.58329 12.4254 2.29163 10.0004 2.29163Z" fill="black" fillOpacity="0.4" />
                                        </svg>
                                        <svg className='align-self-center' width="2" height="50" viewBox="0 0 2 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M1 0C1.55228 0 2 0.621827 2 1.38889V48.6111C2 49.3782 1.55228 50 1 50C0.447715 50 0 49.3782 0 48.6111V1.38889C0 0.621827 0.447715 0 1 0Z" fill="black" fillOpacity="0.4" />
                                        </svg>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0004 11.8084C8.22539 11.8084 6.77539 10.3667 6.77539 8.58337C6.77539 6.80003 8.22539 5.3667 10.0004 5.3667C11.7754 5.3667 13.2254 6.80837 13.2254 8.5917C13.2254 10.375 11.7754 11.8084 10.0004 11.8084ZM10.0004 6.6167C8.91706 6.6167 8.02539 7.50003 8.02539 8.5917C8.02539 9.68337 8.90872 10.5667 10.0004 10.5667C11.0921 10.5667 11.9754 9.68337 11.9754 8.5917C11.9754 7.50003 11.0837 6.6167 10.0004 6.6167Z" fill="black" fillOpacity="0.4" />
                                            <path d="M10.0004 18.9666C8.76706 18.9666 7.52539 18.5 6.55872 17.575C4.10039 15.2083 1.38372 11.4333 2.40872 6.94163C3.33372 2.86663 6.89206 1.04163 10.0004 1.04163C10.0004 1.04163 10.0004 1.04163 10.0087 1.04163C13.1171 1.04163 16.6754 2.86663 17.6004 6.94996C18.6171 11.4416 15.9004 15.2083 13.4421 17.575C12.4754 18.5 11.2337 18.9666 10.0004 18.9666ZM10.0004 2.29163C7.57539 2.29163 4.45872 3.58329 3.63372 7.21663C2.73372 11.1416 5.20039 14.525 7.43372 16.6666C8.87539 18.0583 11.1337 18.0583 12.5754 16.6666C14.8004 14.525 17.2671 11.1416 16.3837 7.21663C15.5504 3.58329 12.4254 2.29163 10.0004 2.29163Z" fill="black" fillOpacity="0.4" />
                                        </svg>
                                    </div>
                                    <div className=' d-flex flex-column align-items-start text-start' style={{ height: "90px" }}>
                                        <p className='m-0 fw-medium'>{trajet.from_address.name} le {new Date(trajet.go_time).toLocaleDateString('fr-FR', {
                                            month: 'numeric',
                                            day: 'numeric',
                                        })}</p>
                                        <p className='m-0 fw-medium mt-auto'>{trajet.to_address.name} le {new Date(trajet.arrive_time).toLocaleDateString('fr-FR', {
                                            month: 'numeric',
                                            day: 'numeric',
                                        })}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 col-md-3">
                            <div className="card-body position-relative">
                                <button className='btn bg-transparent position-absolute top-0 end-0 p-2 w-auto' onClick={() => { navigate(`/report`, { state: trajet }) }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 14.75C11.59 14.75 11.25 14.41 11.25 14V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75Z" fill="black" fillOpacity="0.88" />
                                        <path d="M12 17.9996C11.94 17.9996 11.87 17.9896 11.8 17.9796C11.74 17.9696 11.68 17.9496 11.62 17.9196C11.56 17.8996 11.5 17.8696 11.44 17.8296C11.39 17.7896 11.34 17.7496 11.29 17.7096C11.11 17.5196 11 17.2596 11 16.9996C11 16.7396 11.11 16.4796 11.29 16.2896C11.34 16.2496 11.39 16.2096 11.44 16.1696C11.5 16.1296 11.56 16.0996 11.62 16.0796C11.68 16.0496 11.74 16.0296 11.8 16.0196C11.93 15.9896 12.07 15.9896 12.19 16.0196C12.26 16.0296 12.32 16.0496 12.38 16.0796C12.44 16.0996 12.5 16.1296 12.56 16.1696C12.61 16.2096 12.66 16.2496 12.71 16.2896C12.89 16.4796 13 16.7396 13 16.9996C13 17.2596 12.89 17.5196 12.71 17.7096C12.66 17.7496 12.61 17.7896 12.56 17.8296C12.5 17.8696 12.44 17.8996 12.38 17.9196C12.32 17.9496 12.26 17.9696 12.19 17.9796C12.13 17.9896 12.06 17.9996 12 17.9996Z" fill="black" fillOpacity="0.88" />
                                        <path d="M18.06 22.1601H5.94001C3.99001 22.1601 2.50001 21.4501 1.74001 20.1701C0.990006 18.8901 1.09001 17.2401 2.04001 15.5301L8.10001 4.63009C9.10001 2.83009 10.48 1.84009 12 1.84009C13.52 1.84009 14.9 2.83009 15.9 4.63009L21.96 15.5401C22.91 17.2501 23.02 18.8901 22.26 20.1801C21.5 21.4501 20.01 22.1601 18.06 22.1601ZM12 3.34009C11.06 3.34009 10.14 4.06009 9.41001 5.36009L3.36001 16.2701C2.68001 17.4901 2.57001 18.6101 3.04001 19.4201C3.51001 20.2301 4.55001 20.6701 5.95001 20.6701H18.07C19.47 20.6701 20.5 20.2301 20.98 19.4201C21.46 18.6101 21.34 17.5001 20.66 16.2701L14.59 5.36009C13.86 4.06009 12.94 3.34009 12 3.34009Z" fill="black" fillOpacity="0.88" />
                                    </svg>

                                </button>

                                <p className="fw-bold" style={{ color: "#1f406d", fontSize: "22px" }}>{trajet.prix} €</p>
                                <div className='mt-auto border-start border-2 border-secondary-subtle ps-1'>
                                    <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>{t('qteSM')} : {coli.qte}</p>
                                    <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>Dim : {coli.length} x {coli.width} x {coli.height} cm</p>
                                    <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>{t("weight")}: {weightList[coli.weight - 1]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    );
}
export default TrajetCard;