import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import PaginationComponent from "../../../components/paginations/pagination";
import ColisCollapse from "../../../components/colisCollapse/colisCollapse";
import style from "../style.module.css"
import { useTranslation } from "react-i18next";

function Livraisons() {
    const { t } = useTranslation()
    const [trajets, setTrajets] = useState([]);
    const [ttrajets, setTtrajets] = useState([]);


    const suivi = (type, index) => {
        try {
            let id
            if (type === "trajet") {
                id = trajets[index]._id
            } else if (type === "ttrajet") {

                id = ttrajets[index].offre._id
            }
            else
                return
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }
    const columns = t('livraisonCols', { returnObjects: true });
    const [page1, setPage1] = useState(1)
    const [lastPage1, setLastPage1] = useState()
    const [page2, setPage2] = useState(1)
    const [lastPage2, setLastPage2] = useState()
    const fetchCount1 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/trajets/livreCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage1(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchCount2 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/TtrajetOffres/livreCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage2(calculatedLastPage);
            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData1 = async (page) => {
        try {
            const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/trajets/AllLivres?page=${page}`);
            const trajetData = trajetResponse.data;
            console.log("fetched trajets", trajetData)
            const promises = trajetData.map(async (TrajetItem) => {
                try {
                    const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                    const offreResponse = await axios.get(`${process.env.REACT_APP_SERVER}/offres/livre?trajet=${TrajetItem._id}`);
                    TrajetItem.colis = colisResponse.data ?? [];
                    TrajetItem.offre = offreResponse.data ?? [];
                    return TrajetItem;
                } catch (error) {
                    console.error("Error fetching colis and offres data:", error);
                    return null; // Returning null to indicate failure
                }
            });

            const updatedTrajetsData = await Promise.all(promises);
            const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
            console.log("trajets ", filteredTrajetsData)
            setTrajets(filteredTrajetsData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchData2 = async (page) => {
        try {
            const trajetOffreResponse = await axios.get(`${process.env.REACT_APP_SERVER}/TtrajetOffres/AllLivre?page=${page}`);
            const trajetOffreData = trajetOffreResponse.data;
            const promises = trajetOffreData.map(async (TrajetOffreItem) => {
                try {
                    const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/${TrajetOffreItem.ttrajet}`);
                    const trajet = trajetResponse.data;
                    trajet.colis = [];
                    for (let coli of TrajetOffreItem.colis) {
                        await axios.get(`${process.env.REACT_APP_SERVER}/colis/${coli}`)
                            .then((res) => {
                                trajet.colis.push(res.data)
                            })
                    }
                    trajet.offre = TrajetOffreItem;
                    return trajet;
                } catch (error) {
                    console.error("Error fetching colis and offres data:", error);
                    return null; // Returning null to indicate failure
                }
            });

            const updatedTrajetsData = await Promise.all(promises);
            const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
            console.log(filteredTrajetsData)
            setTtrajets(filteredTrajetsData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        fetchCount1();
        fetchCount2();
        fetchData1(page1);
        fetchData2(page2);
    }, []);

    return (
        <Row style={{ maxWidth: "100%" }}>
            <nav>
                <div className={`${style.navs} nav nav-tabs`} id="nav-tab" role="tablist">
                    <button className="nav-link w-50 active" id="nav-colis-tab" data-bs-toggle="tab" data-bs-target="#nav-colis" type="button" role="tab" aria-controls="nav-colis" aria-selected="true"> <h5 className="text-start fw-lighter m-0">{t('packages')}</h5> </button>
                    <button className="nav-link w-50" id="nav-trajet-tab" data-bs-toggle="tab" data-bs-target="#nav-trajet" type="button" role="tab" aria-controls="nav-trajet" aria-selected="false"><h5 className="text-start fw-lighter m-0">{t('trips')}</h5> </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-colis" role="tabpanel" aria-labelledby="nav-colis-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">{t('packagesDelivers')}</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion1">
                                    {trajets.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr  >
                                                {columns.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "en cours" ? (
                                                                    <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>

                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => suivi("trajet", index)}>
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 14.1699C9.87 14.1699 8.13 12.4399 8.13 10.2999C8.13 8.15994 9.87 6.43994 12 6.43994C14.13 6.43994 15.87 8.16994 15.87 10.3099C15.87 12.4499 14.13 14.1699 12 14.1699ZM12 7.93994C10.7 7.93994 9.63 8.99994 9.63 10.3099C9.63 11.6199 10.69 12.6799 12 12.6799C13.31 12.6799 14.37 11.6199 14.37 10.3099C14.37 8.99994 13.3 7.93994 12 7.93994Z" fill="currentColor" fill-opacity="0.88" />
                                                                                <path d="M12 22.76C10.52 22.76 9.02999 22.2 7.86999 21.09C4.91999 18.25 1.65999 13.72 2.88999 8.33C3.99999 3.44 8.26999 1.25 12 1.25C12 1.25 12 1.25 12.01 1.25C15.74 1.25 20.01 3.44 21.12 8.34C22.34 13.73 19.08 18.25 16.13 21.09C14.97 22.2 13.48 22.76 12 22.76ZM12 2.75C9.08999 2.75 5.34999 4.3 4.35999 8.66C3.27999 13.37 6.23999 17.43 8.91999 20C10.65 21.67 13.36 21.67 15.09 20C17.76 17.43 20.72 13.37 19.66 8.66C18.66 4.3 14.91 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <button className="btn btn-outline-primary my-1 btn-sm col-12 disabled">Aucune</button>
                                                                )}
                                                            </>

                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.prix ? row.offre.prix : t('notFound')
                                                        ) : column[1] === "etat" ? (
                                                            row.offre.etat ? row.offre.etat : t('notFound')
                                                        ) : (
                                                            row[column[1]] || t('notFound')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>

                        </CardBody>
                        <PaginationComponent page={page1} lastPage={lastPage1} setPage={setPage1} fetchData={fetchData1} />

                    </Card>
                </div >
                <div className="tab-pane fade" id="nav-trajet" role="tabpanel" aria-labelledby="nav-trajet-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">{t('tripsDelivers')}</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion2">
                                    {ttrajets.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr >
                                                {columns.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "en cours" ? (
                                                                    <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => suivi("ttrajet", index)}>
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 14.1699C9.87 14.1699 8.13 12.4399 8.13 10.2999C8.13 8.15994 9.87 6.43994 12 6.43994C14.13 6.43994 15.87 8.16994 15.87 10.3099C15.87 12.4499 14.13 14.1699 12 14.1699ZM12 7.93994C10.7 7.93994 9.63 8.99994 9.63 10.3099C9.63 11.6199 10.69 12.6799 12 12.6799C13.31 12.6799 14.37 11.6199 14.37 10.3099C14.37 8.99994 13.3 7.93994 12 7.93994Z" fill="currentColor" fill-opacity="0.88" />
                                                                                <path d="M12 22.76C10.52 22.76 9.02999 22.2 7.86999 21.09C4.91999 18.25 1.65999 13.72 2.88999 8.33C3.99999 3.44 8.26999 1.25 12 1.25C12 1.25 12 1.25 12.01 1.25C15.74 1.25 20.01 3.44 21.12 8.34C22.34 13.73 19.08 18.25 16.13 21.09C14.97 22.2 13.48 22.76 12 22.76ZM12 2.75C9.08999 2.75 5.34999 4.3 4.35999 8.66C3.27999 13.37 6.23999 17.43 8.91999 20C10.65 21.67 13.36 21.67 15.09 20C17.76 17.43 20.72 13.37 19.66 8.66C18.66 4.3 14.91 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <button className="btn btn-outline-primary my-1 btn-sm col-12 disabled">Aucune</button>
                                                                )}
                                                            </>
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : t('notFound')
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : t('notFound')
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.prix ? row.offre.prix : t('notFound')
                                                        ) : column[1] === "etat" ? (
                                                            row.offre.etat ? row.offre.etat : t('notFound')
                                                        ) : (
                                                            row[column[1]] || t('notFound')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                        <PaginationComponent page={page2} lastPage={lastPage2} setPage={setPage2} fetchData={fetchData2} />

                    </Card>
                </div>
            </div >
        </Row >
    )
}

export default Livraisons;
