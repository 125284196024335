import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

function ModifierMail() {
    const { t } = useTranslation()
    const emailEditorRef = useRef(null);
    const [mail, setMail] = useState(null);
    const location = useLocation()
    const navigate = useNavigate()
    const fetchData = async () => {
        try {
            let mail = location.state.mail
            setMail(mail)
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/mails/${mail._id}`);
            const { content } = response.data;
            setMail({ ...mail, content: content })
            console.log('Fetched content:', content);

            if (emailEditorRef.current && content) {
                // Wrap raw HTML in a design JSON
                const design = content.design || { body: { rows: [] } };
                console.log('Design JSON:', design);
                emailEditorRef.current.editor.loadDesign(design);
            } else {
                console.error('Content is missing or invalid.');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const exportHtml = async () => {
        try {
            emailEditorRef.current.editor.exportHtml(async (data) => {
                const { html, design } = data;

                console.log('Exported HTML:', html);

                await axios.put(`${process.env.REACT_APP_SERVER}/mails/${mail._id}`, {
                    content: data, // Save the raw HTML
                    type: mail.type,
                });

                console.log('Content successfully sent to the server.');
                navigate('/dashboard/mails')
            });
        } catch (error) {
            console.error('Error during exportHtml:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Row style={{ maxWidth: "100%" }}>
            <EmailEditor ref={emailEditorRef} />
            <div className="col-12 col-md-8">
                <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        value={mail?.type}
                        placeholder="Type de mail"
                        onChange={(e) => setMail({ ...mail, type: e.target.value })}
                    />
                    <button
                        className="btn btn-outline-secondary w-auto"
                        type="button"
                        onClick={exportHtml}
                    >
                        {t('edit')}
                    </button>
                </div>
            </div>
        </Row>
    );
}

export default ModifierMail
