import React, { useState } from "react";
import { Table } from "reactstrap";
import classes from "./style.module.css";
import fetchImage from "../fetchImg";
import { useTranslation } from "react-i18next";

function ColisCollapse({ colis, colspan }) {
    const { t } = useTranslation()
    const colisCols = t("colisCols", { returnObjects: true })
    const [openCollapse, setOpenCollapse] = useState(null);
    const [rotateArrow, setRotateArrow] = useState(false);
    const weightList = t("weightList", { returnObjects: true })
    const handleCollapseToggle = (id) => {
        setOpenCollapse(openCollapse === id ? null : id);
        setRotateArrow(openCollapse !== id);
    };

    return (
        <React.Fragment>
            <tr>
                <td
                    onClick={() => handleCollapseToggle(colis._id)}
                    colSpan={colspan}
                    style={{ cursor: "pointer" }}
                    className={`m-0 pt-0 border-bottom-0 text-center ${rotateArrow ? 'arrow-rotated' : ''}`}
                    data-bs-toggle="collapse"
                    data-bs-target={`#${colis._id}`}
                    aria-expanded={openCollapse === colis._id}
                    aria-controls={colis._id}
                >
                    <div className="text-center position-relative p-1" style={{ height: '0px' }}>
                        <div style={{ borderTop: '1px solid #dee2e6' }}></div>
                        <svg className={`position-absolute start-50 translate-middle `} width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#999" />
                            <path className={`${classes.arrow_path} ${rotateArrow ? classes.arrow_rotated : ''}`} d="M12 15.0099C11.81 15.0099 11.62 14.9399 11.47 14.7899L7.94 11.2599C7.65 10.9699 7.65 10.4899 7.94 10.1999C8.23 9.90992 8.71 9.90992 9 10.1999L12 13.1999L15 10.1999C15.29 9.90992 15.77 9.90992 16.06 10.1999C16.35 10.4899 16.35 10.9699 16.06 11.2599L12.53 14.7899C12.38 14.9399 12.19 15.0099 12 15.0099Z" fill="black" fillOpacity="1" />
                        </svg>
                    </div>
                </td>
            </tr>

            <tr id={colis._id} className={`accordion-collapse collapse ${openCollapse === colis._id ? 'show' : ''}`} aria-labelledby={`heading${colis._id}`} data-bs-parent="#accordionExample">
                <td colSpan={colspan}>
                    <Table>
                        <thead className="text-primary">
                            <tr>
                                {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {colis.map((colisItem) => (
                                <tr key={colisItem._id}>
                                    {colisCols.map(column => (
                                        <td key={column[1]}>
                                            {column[1] === "photos" ? (
                                                <img src={fetchImage(colisItem.photos[0])} style={{ maxHeight: "40px" }} alt="Coli" />
                                            ) : column[1] === "weight" ? (
                                                weightList[colisItem.weight - 1]
                                            ) : (
                                                colisItem[column[1]] || t('notFound')
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </td>
            </tr>
        </React.Fragment>
    );
}

export default ColisCollapse;