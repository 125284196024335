import axios from 'axios';
import createRefresh from 'react-auth-kit/createRefresh';

const refresh = createRefresh({
    interval: 10, // Refresh every 1 minute
    refreshApiCallback: async (param) => {

        try {
            console.log("Refreshing"); // Ensure this log is present
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/refresh`, param, {
                headers: { 'Authorization': `Bearer ${param.authToken}` }
            });

            return {
                isSuccess: true,
                newAuthToken: response.data.token,
                newAuthTokenExpireIn: 20, // Set token expiration appropriately
                newRefreshTokenExpiresIn: 60 // Set refresh token expiration appropriately
            };
        }
        catch (error) {
            console.error(error);
            return {
                isSuccess: false
            };
        }
    }
});

export default refresh;
