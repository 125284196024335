import { useTranslation } from "react-i18next";
import TrajetCard from "../cards/trajetCard/trajetCard";
import { Container, Row, Col } from 'react-bootstrap';
import Mapinput from "../../components/map/input";
import PaginationComponent from "../paginations/pagination";
const TrajetFilter = ({ errors, fetchData, trajets, place1, place2, setPage, lastPage, setFilter, filter, handlePlaceSelection1, handlePlaceSelection2, handleWeightChange, page }) => {
    const { t } = useTranslation()
    const weightList = t('weightList', { returnObjects: true })
    const checkDate = (e) => {
        console.log(e.target.value)
    }

    const isValidDate = (dateString) => {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        return datePattern.test(dateString);
    };
    const fetchForPagination = (page) => {
        fetchData(filter, page)
    }
    const formatDate = (date) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };
    return (
        <Container>
            <Row className="mb-3">
                <Col>
                    <Mapinput firstValue={filter?.from_address?.name} onChange={handlePlaceSelection1} placeholder={t('departure')} />
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <Mapinput firstValue={filter?.to_address?.name} onChange={handlePlaceSelection2} placeholder={t('arrival')} />
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <label>{t('departureDate')}</label>
                    <input type="date"
                        min={formatDate(new Date())}

                        className={`form-control ${errors.go_time ? "" : "is-invalid"}`}
                        value={filter.go_time}
                        onChange={(e) => {
                            const dateValue = e.target.value;
                            if (isValidDate(dateValue)) {
                                checkDate(e);
                                setFilter({ ...filter, go_time: dateValue });
                            } else {
                                console.error("Invalid date format:", dateValue);
                            }
                        }} />
                    <div className="invalid-feedback">
                        Veuillez entrer une date valide
                    </div>
                </Col>
                <Col>
                    <label>{t('arrivalDate')}</label>
                    <input
                        min={formatDate(new Date())}
                        type="date"
                        className={`form-control ${errors.arrive_time ? "" : "is-invalid"}`}
                        value={filter.arrive_time}
                        onChange={(e) => {
                            const dateValue = e.target.value;
                            if (isValidDate(dateValue)) {
                                checkDate(e);
                                setFilter({ ...filter, arrive_time: dateValue });
                            } else {
                                console.error("Invalid date format:", dateValue);
                            }
                        }} />
                    <div className="invalid-feedback">
                        Veuillez entrer une date valide
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <label>{t('weight')}</label>
                <div className={`row m-0`}>
                    <input
                        className={`btn-check col-12 col-sm`}
                        type="radio"
                        required
                        name={`weight`}
                        value="1"
                        id={`-5kG`}
                        checked={filter.weight === 1}
                        onChange={handleWeightChange} />
                    <label
                        className={`btn btn-outline-primary col-12 col-sm`}
                        htmlFor={`-5kG`}
                    >
                        {weightList[0]}
                    </label>

                    <input
                        className={`btn-check col-12 col-sm`}
                        type="radio"
                        name={`weight`} value="2"
                        checked={filter.weight === 2}

                        required
                        id={`5KG à 30KG`}
                        onChange={handleWeightChange}
                    />
                    <label
                        className={`btn btn-outline-primary col-12 col-sm`}
                        htmlFor={`5KG à 30KG`}
                    >
                        {weightList[1]}
                    </label>

                    <input
                        className={`btn-check col-12 col-sm`}
                        type="radio"
                        name={`weight`} value="3"
                        checked={filter.weight === 3}

                        required
                        id={`30KG à 50KG`}
                        onChange={handleWeightChange} />
                    <label
                        className={`btn btn-outline-primary col-12 col-sm`}
                        htmlFor={`30KG à 50KG`}
                    >
                        {weightList[2]}
                    </label>

                    <input
                        className={`btn-check col-12 col-sm`}
                        type="radio"
                        name={`weight`}
                        value="4"
                        checked={filter.weight === 4}

                        required
                        id={`50KG à 100KG`}
                        onChange={handleWeightChange}
                    />
                    <label
                        className={`btn btn-outline-primary col-12 col-sm`}
                        htmlFor={`50KG à 100KG`}
                    >
                        {weightList[3]}
                    </label>

                    <input
                        className={`btn-check col-12 col-sm`}
                        type="radio"
                        name={`weight`}
                        value="5"
                        checked={filter.weight === 5}

                        required
                        id={`100KG`}
                        onChange={handleWeightChange} />
                    <label
                        className={`btn btn-outline-primary col-12 col-sm`}
                        htmlFor={`100KG`}
                    >
                        <p>{weightList[4]}</p>
                    </label>
                    <div className="invalid-feedback col-12">
                        {t('weightError')}
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-2" >
                    <button className="btn btn-outline-danger  w-auto" onClick={() => {
                        let filter = {
                            from_address: place1 || { name: "" },
                            to_address: place2 || { name: "" },
                            go_time: "",
                            arrive_time: "",
                        }
                        fetchData(filter, 1)
                    }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 6.73046C20.98 6.73046 20.95 6.73046 20.92 6.73046C15.63 6.20046 10.35 6.00046 5.11998 6.53046L3.07998 6.73046C2.65998 6.77046 2.28998 6.47046 2.24998 6.05046C2.20998 5.63046 2.50998 5.27046 2.91998 5.23046L4.95998 5.03046C10.28 4.49046 15.67 4.70046 21.07 5.23046C21.48 5.27046 21.78 5.64046 21.74 6.05046C21.71 6.44046 21.38 6.73046 21 6.73046Z" fill="currentColor" fill-opacity="0.88" />
                            <path d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z" fill="currentColor" fill-opacity="0.88" />
                            <path d="M15.21 22.7496H8.79002C5.30002 22.7496 5.16002 20.8196 5.05002 19.2596L4.40002 9.18959C4.37002 8.77959 4.69002 8.41959 5.10002 8.38959C5.52002 8.36959 5.87002 8.67959 5.90002 9.08959L6.55002 19.1596C6.66002 20.6796 6.70002 21.2496 8.79002 21.2496H15.21C17.31 21.2496 17.35 20.6796 17.45 19.1596L18.1 9.08959C18.13 8.67959 18.49 8.36959 18.9 8.38959C19.31 8.41959 19.63 8.76959 19.6 9.18959L18.95 19.2596C18.84 20.8196 18.7 22.7496 15.21 22.7496Z" fill="currentColor" fill-opacity="0.88" />
                            <path d="M13.66 17.25H10.33C9.91999 17.25 9.57999 16.91 9.57999 16.5C9.57999 16.09 9.91999 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z" fill="currentColor" fill-opacity="0.88" />
                            <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="currentColor" fill-opacity="0.88" />
                        </svg>
                    </button>
                </div>
                <div className="col-10">
                    <button className="btn btn-primary col-12" onClick={() => fetchData(filter, page)}>{t('search')}</button>
                </div>
            </Row>
            {trajets.map((trajet) => (
                <TrajetCard trajet={trajet} />
            )
            )}
            <div className="d-flex justify-content-center">
                <PaginationComponent page={page} lastPage={lastPage} fetchData={fetchForPagination} setPage={setPage} /> </div>
        </Container>

    )
}

export default TrajetFilter