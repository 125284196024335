import React, { useEffect, useState } from "react";
import Map from "../../components/map/map";
import Mapinput from "../../components/map/input";
import classes from "./voir_colis_disponible.module.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import TrajetCard from "../../components/cards/trajetCard/trajetCard";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import TrajetFilter from "../../components/filters/trajets";

const VoirColisDispo = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const location = useLocation();
    const { place1, place2 } = location.state || {}
    const [trajets, setTrajets] = useState([]);

    const [filter, setFilter] = useState({
        from_address: place1 || { name: "" },
        to_address: place2 || { name: "" }
    });
    const [errors, setErrors] = useState({ go_time: true, arrive_time: true })
    const [oldFilter, setOldFilter] = useState({})
    const handlePlaceSelection1 = (place) => {
        setFilter({ ...filter, from_address: place });
    };
    const fetchCount = async (filter) => {
        try {
            const trajetResponse = await axios.get(
                `${process.env.REACT_APP_SERVER}/trajets/countByFilter`,
                { params: { filter: filter } }
            );
            const count = trajetResponse.data.count;
            const calculatedLastPage = Math.ceil(count / 5);
            setLastPage(calculatedLastPage);
            console.log(count)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const handlePlaceSelection2 = (place) => {
        setFilter({ ...filter, to_address: place });
    };
    const handleWeightChange = (e) => {
        setFilter({ ...filter, weight: Number(e.target.value) });
    }
    const isSameDate = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    };
    const CheckDate = (filter) => {
        let v = true
        let errors = { go_time: true, arrive_time: true }
        if (filter.go_time) {
            if (!(new Date(filter.go_time) > new Date()) && !isSameDate(new Date(filter.go_time), new Date())) {
                errors.go_time = false
                v = false
            } else if (filter.arrive_time) {
                if (new Date(filter.arrive_time) < new Date(filter.go_time)) {
                    errors.arrive_time = false;
                    v = false
                }
            }
        }
        else if (filter.arrive_time) {
            if (!(new Date(filter.arrive_time) > new Date()) && !isSameDate(new Date(filter.arrive_time), new Date())) {
                errors.arrive_time = false;
                v = false
            }
        }
        setErrors(errors)
        return v
    }
    const fetchData = async (newFilter, currentPage) => {
        try {
            if (!CheckDate(newFilter)) {
                return
            }
            setFilter(newFilter);
            console.log(newFilter)
            // Check if the filter has changed; reset the page if necessary
            const isFilterChanged = JSON.stringify(newFilter) !== JSON.stringify(oldFilter);
            const pageToFetch = isFilterChanged ? 1 : currentPage;
            const isPageChanged = pageToFetch !== page;
            if (isFilterChanged || isPageChanged) {
                // Fetch trajet data
                if (isFilterChanged) { fetchCount(filter); }
                const trajetResponse = await axios.get(
                    `${process.env.REACT_APP_SERVER}/trajets`,
                    { params: { page: pageToFetch, filter: newFilter } }
                );
                const trajetData = trajetResponse.data;
                let filteredTrajetsData = trajetData.filter(item => item !== null);
                // Fetch colis for each trajet item
                const promises = filteredTrajetsData.map(async (TrajetItem) => {
                    try {
                        const colisResponse = await axios.get(
                            `${process.env.REACT_APP_SERVER}/colis/trajet`,
                            { params: { trajet: TrajetItem._id } }
                        );
                        return { ...TrajetItem, colis: colisResponse.data || [] };
                    } catch (error) {
                        console.error(`Error fetching colis for trajet ${TrajetItem._id}:`, error);
                        return null; // Handle failed requests
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);
                filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
                // Update state with the new data
                console.log(filteredTrajetsData)

                setTrajets(filteredTrajetsData);

                setOldFilter(newFilter);
                setPage(pageToFetch); // Update the current page

            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchCount(filter);
        fetchData(filter, 1);
    }, []);

    return (
        <>
            <Helmet>
                <title>Voir colis</title>
                <link rel="canonical" href="https://www.colispo.com/voirColis" />
                <meta name="description" content="Voir les colis disponible pour tranporter." />
            </Helmet>
            <div className={classes.container}>
                <div className={`row w-100`} style={{ height: '100%' }}>
                    {/* Offcanvas Button for Mobile */}
                    <div className="d-md-none col-12 mt-1 d-flex align-items-center justify-content-between">
                        <button
                            className={`btn w-auto btn-outline-secondary`}
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasWithBothOptions"
                            aria-controls="offcanvasWithBothOptions"
                        >
                            {t('filter')}
                        </button>
                        <h3 className="mb-0">Liste des colis</h3>

                    </div>

                    {/* Offcanvas Component for Mobile */}
                    <div
                        className="offcanvas offcanvas-start"
                        data-bs-scroll="true"
                        tabIndex="-1"
                        id="offcanvasWithBothOptions"
                        aria-labelledby="offcanvasWithBothOptionsLabel"
                    >
                        <div className="offcanvas-header">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="offcanvas-body">
                            <TrajetFilter
                                filter={filter}
                                setFilter={setFilter}
                                page={page}
                                fetchData={fetchData}
                                place1={place1}
                                place2={place2}
                                trajets={trajets}
                                handlePlaceSelection1={handlePlaceSelection1}
                                handlePlaceSelection2={handlePlaceSelection2}
                                handleWeightChange={handleWeightChange}
                                errors={errors}
                                setPage={setPage}
                                lastPage={lastPage}
                            />
                        </div>
                    </div>

                    {/* Sidebar for Larger Screens */}
                    <div className="d-none d-md-block col-md-6 col-lg-5 pe-0">
                        <div className={`${classes.sidebar}`}>
                            <TrajetFilter
                                filter={filter}
                                setFilter={setFilter}
                                page={page}
                                fetchData={fetchData}
                                place1={place1}
                                place2={place2}
                                trajets={trajets}
                                handlePlaceSelection1={handlePlaceSelection1}
                                handlePlaceSelection2={handlePlaceSelection2}
                                handleWeightChange={handleWeightChange}
                                errors={errors}
                                setPage={setPage}
                                lastPage={lastPage}
                            />
                        </div>
                    </div>

                    {/* Map Section */}
                    <div className="col-12 col-md-6 col-lg-7 p-0 h-100">
                        <div style={{ height: '100%', overflow: 'auto' }}>
                            <Map data={trajets} />
                        </div>
                    </div>
                </div>

            </div >
        </>
    );
};

export default VoirColisDispo;
