import { useEffect, useRef, useState } from "react";
import classes from "./ajouter-colis.module.css"
import axios from "axios";
import Mapinput from "../../components/map/input";
import { RelaiMapSelection } from "../../components/map/map";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import { useTranslation } from "react-i18next";

function Ajouter_trajet_form({ trajet, setTrajet, offre, setOffre, goDateValid, arriveDateValid }) {
    const modalRef = useRef(null)
    const modalRef1 = useRef(null)
    const modalRef2 = useRef(null)
    const { t } = useTranslation()
    const modalRef3 = useRef(null)
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    }
    const handlePlaceSelection = (place, addressType) => {
        setTrajet({ ...trajet, [addressType]: place });
        console.log(place)
    };
    const handleRelaiSelection1 = (place) => {
        console.log("Setting relai1");
        setOffre({ ...offre, relai1: place })
    };
    const handleRelaiSelection2 = (place) => {
        console.log("Setting relai2");
        setOffre({ ...offre, relai2: place })
    };
    useEffect(() => {
        if (trajet.transporteur) {
            fetchRelais()
            openModal(modalRef1)
        }
    }, [])
    const weightList = t('weightList', { returnObjects: true });
    const [selectedTrajet, setSelectedTrajet] = useState();
    const [relai1, Setrelai1] = useState(false);
    const [relai2, SetRelai2] = useState(false);
    const getRelaiName = async (id) => {
        try {
            let address
            await axios.get(`${process.env.REACT_APP_SERVER}/relais/${id}`).then((res) => {
                address = res.data.address
            }).catch((err) => {
                console.log(err)
            });
            return address
        }
        catch (err) {
            console.log(err)
        }
    }
    const fetchRelais = async () => {
        const n1 = await getRelaiName(trajet.relai1)
        const n2 = await getRelaiName(trajet.relai2)
        console.log(n1, n2)
        setTrajet({ ...trajet, relai1: n1, relai2: n2 })
    }
    const [error1, setError1] = useState()
    const [error2, setError2] = useState()
    const [error12, setError12] = useState()
    const [error22, setError22] = useState()
    const validateOffre = () => {
        let error1, error2, error12, error22 = false
        if (relai1 || !offre.mam1) {
            if (relai1 && !offre.relai1) {
                console.log("Error 12")
                error12 = true
            }
            else if (!offre.mam1 && !relai1) {
                console.log("Error 1")
                error1 = true
            }

        }
        if (relai2 || !offre.mam2) {
            if (relai2 && !offre.relai2) {
                console.log("Error 22")
                error22 = true
            }
            else if (!offre.mam2 && !relai2) {
                console.log("Error 2")
                error2 = true

            }

        }
        if (!error1 && !error2 && !error12 && !error22)

            closeModal(modalRef1)

        console.log(offre)
        setError1(error1)
        setError2(error2)
        setError12(error12)
        setError22(error22)
    }
    const checkDate = (e) => {
        console.log(e.target.value)
    }

    const isValidDate = (dateString) => {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        return datePattern.test(dateString);
    };
    const formatDate = (date) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };
    return (
        <div className={`${classes.FromBox} `}>
            <div className=" ">
                <div className="row">
                    <label>{t("departure")}</label>
                    <Mapinput firstValue={trajet?.from_address?.name || ""} onChange={place => handlePlaceSelection(place, 'from_address')} placeholder={t('adressInput')} disabled={trajet.transporteur} />
                </div>
                <div className="row mt-2">
                    <label>{t("arrival")}</label>
                    <Mapinput firstValue={trajet?.to_address?.name || ""} onChange={place => handlePlaceSelection(place, 'to_address')} placeholder={t('adressInput')} disabled={trajet.transporteur} />
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <label>{t("departureDate")}</label>
                        <input
                            min={formatDate(new Date())}

                            disabled={trajet.transporteur}
                            className={`form-control ${goDateValid == true ? 'is-valid' : goDateValid == false ? 'is-invalid' : ""}`} type="date" required
                            value={trajet.go_time ? new Date(trajet.go_time).toISOString().split('T')[0] : ''}
                            onChange={(e) => {
                                const dateValue = e.target.value;
                                if (isValidDate(dateValue)) {
                                    checkDate(e);
                                    setTrajet({ ...trajet, go_time: dateValue });
                                } else {
                                    console.error("Invalid date format:", dateValue);
                                }
                            }}
                        />
                        <div className="invalid-feedback">
                            Veuillez saisir une date valide
                        </div>
                    </div>
                    <div className="col">
                        <label>{t("arrivalDate")}</label>
                        <input
                            disabled={trajet.transporteur}
                            value={trajet.arrive_time ? new Date(trajet.arrive_time).toISOString().split('T')[0] : ''}
                            className={`form-control ${arriveDateValid == true ? 'is-valid' : arriveDateValid == false ? 'is-invalid' : ""}`} type="date" required
                            min={formatDate(new Date())}
                            onChange={(e) => {
                                const dateValue = e.target.value;
                                if (isValidDate(dateValue)) {
                                    checkDate(e);
                                    setTrajet({ ...trajet, arrive_time: dateValue });
                                } else {
                                    console.error("Invalid date format:", dateValue);
                                }
                            }}
                        />
                        <div className="invalid-feedback">
                            Veuillez saisir une date valide
                        </div>
                    </div>
                </div>

                {trajet.transporteur && <div className="row mt-4 p-2">
                    <button type="button" onClick={() => {
                        openModal(modalRef1)
                    }} className="btn btn-primary">
                        {t('ctd')}
                    </button>
                </div>
                }


                <div className="row mt-4">
                    <div className="col-1 me-4">
                        <p>{t('att')}</p>
                    </div>
                    <div className="col">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke="#0080FF"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 8V13"
                                stroke="#0080FF"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M11.9945 16H12.0035"
                                stroke="#0080FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div>
                    <p className={`${classes.attention}`}>
                        {t('attText')}
                    </p>
                </div>
            </div>

            <div className="modal fade modal-xl" ref={modalRef1} aria-hidden="true" aria-labelledby="modalToggleLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                        <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                            <h1 className="modal-title fs-5 text-light" id="modalToggleLabel">{t('makeOffer')}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col">
                                    <div className="card rounded-4">
                                        <div className="card-body">
                                            <h5 className="card-title">{t('offreDetails')}</h5>
                                            <h6 className="fw-bold">{t('start')}</h6>
                                            <p>
                                                {t('startH2H')} {trajet && trajet.mam1 === true ? t('yes') : t('no')}
                                            </p>
                                            <p>
                                                {t('startRelai')} <b>{(trajet && trajet.relai1) ? (trajet.relai1) : (t('no'))}</b>
                                            </p>
                                            <h6 className="fw-bold">{t('end')}</h6>
                                            <p>
                                                {t('endH2H')}{trajet && trajet.mam2 === true ? t('yes') : t('no')}
                                            </p>
                                            <p>
                                                {t('endRelai')} <b>{(trajet && trajet.relai2) ? (trajet.relai2) : (t('no'))}</b>
                                            </p>
                                            <h6 className="fw-bold">{t('constraints')} :</h6>
                                            <p>
                                                {t('maxDim')} : {trajet && trajet.max_length && trajet.max_width && trajet.max_height ? (trajet.max_length + " x " + trajet.max_width + " x " + trajet.max_height + " cm") : (t('notSpecified'))}
                                            </p>
                                            <p>
                                                {t('maxWeight')} : {trajet && trajet.max_weight ? (weightList[trajet.max_weight]) : (t('notSpecified'))}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className="card rounded-4 mt-4 mt-lg-0">
                                        <div className="card-body">
                                            <h5 className="card-title" >{t('yourOffre')}</h5>
                                            <fieldset>
                                                <h6 className="fw-bold">{t('start')}</h6>
                                                <div className="d-flex">
                                                    <p className="me-4 text-nowrap">{t('startH2H')}</p>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="mam1" // Separate name for this group
                                                            id="r1"
                                                            value='oui'
                                                            checked={offre.mam1}
                                                            onChange={(event) => {

                                                                setOffre({ ...offre, mam1: true, relai1: undefined });
                                                                Setrelai1(false);

                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r1">{t('yes')}</label>
                                                    </div>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="mam1" // Separate name for this group
                                                            id="r2"
                                                            value="non"
                                                            checked={!offre.mam1}
                                                            onChange={(event) => {

                                                                setOffre({ ...offre, mam1: false });
                                                                Setrelai1(true)

                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r2">{t('no')}</label>
                                                    </div>
                                                </div>

                                                <div className="d-flex">
                                                    <p className="me-4 text-nowrap">{t('startRelai')}</p>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="relai1" // Separate name for this group
                                                            id="r3"
                                                            value='oui'
                                                            checked={relai1}
                                                            onChange={(event) => {

                                                                Setrelai1(true);
                                                                setOffre({ ...offre, mam1: false }); // Ensure mam1 is false when "relai" is selected
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r3">{t('yes')}</label>
                                                    </div>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="relai1"
                                                            id="r4"
                                                            value="non"
                                                            checked={!relai1}
                                                            onChange={(event) => {

                                                                Setrelai1(false);
                                                                setOffre({ ...offre, relai1: undefined, mam1: true });
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r4">{t('no')}</label>
                                                    </div>
                                                </div>
                                                {relai1 === true && (
                                                    <button
                                                        className="btn btn-outline-primary col-12 mb-4"
                                                        onClick={() => {
                                                            closeModal(modalRef1)
                                                            openModal(modalRef2)
                                                        }}>
                                                        {t('selectRelai')}
                                                    </button>
                                                )}
                                                {error1 && <div className="alert alert-danger">
                                                    Veuillez sélectionner une option
                                                </div>}
                                                {error12 && <div className="alert alert-danger">
                                                    Veuillez sélectionner une point relais
                                                </div>}
                                            </fieldset>

                                            <fieldset className="">
                                                <h6 className="fw-bold">{t('end')}</h6>
                                                <div className="d-flex">
                                                    <p className="me-4">{t('endH2H')}</p>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="mam2" // Separate name for this group
                                                            id="r5"
                                                            value='oui'
                                                            checked={offre.mam2}
                                                            onChange={(event) => {
                                                                //  handleInputChange(event);
                                                                setOffre({ ...offre, mam2: true, relai2: undefined });
                                                                SetRelai2(false);
                                                                // Ensure relai2 is false when "main à main" is selected
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r5">{t('yes')}</label>
                                                    </div>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="mam2"
                                                            id="r6"
                                                            checked={!offre.mam2}
                                                            value="non"
                                                            onChange={(event) => {
                                                                // handleInputChange(event);
                                                                SetRelai2(true);
                                                                setOffre({ ...offre, mam2: false });
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r6">{t('no')}</label>
                                                    </div>
                                                </div>

                                                <div className="d-flex">
                                                    <p className="me-4">{t('endRelai')}</p>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="relai2" // Separate name for this group
                                                            id="r7"
                                                            value='oui'
                                                            checked={relai2}
                                                            onChange={(event) => {
                                                                // handleInputChange(event);
                                                                SetRelai2(true);
                                                                setOffre({ ...offre, mam2: false }); // Ensure mam2 is false when "relai" is selected
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r7">{t('yes')}</label>
                                                    </div>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="relai2"
                                                            id="r8"
                                                            value="non"
                                                            checked={!relai2}
                                                            onChange={(event) => {
                                                                // handleInputChange(event);
                                                                SetRelai2(false);
                                                                setOffre({ ...offre, relai2: undefined, mam2: true });
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r8">{t('no')}</label>
                                                    </div>
                                                </div>
                                                {relai2 === true && (
                                                    <button className="btn btn-outline-primary col-12" onClick={() => {
                                                        closeModal(modalRef1)
                                                        openModal(modalRef3)
                                                    }}>{t('selectRelai')}</button>
                                                )}
                                                {error2 && <div className="alert alert-danger">
                                                    Veuillez sélectionner une option
                                                </div>}
                                                {error22 && <div className="alert alert-danger">
                                                    Veuillez sélectionner une point relais
                                                </div>}
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={() => {
                                closeModal(modalRef1)
                            }}>{t("cancel")}</button>
                            <button type="button" className="btn btn-primary" onClick={() => validateOffre()}>{t('validate')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade modal-xl" ref={modalRef2} data-bs-backdrop="static" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">{t('selectRelai')}</h1>
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    closeModal(modalRef2)
                                    openModal(modalRef1)
                                }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ height: "70vh" }}>
                            <RelaiMapSelection location={trajet?.from_address?.location} setRelai={handleRelaiSelection1} currentModal={modalRef2} nextModalRef={modalRef1} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary"
                                onClick={() => {
                                    closeModal(modalRef2)
                                    openModal(modalRef1)
                                }}>{t("cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade modal-xl" ref={modalRef3} data-bs-backdrop="static" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">{t('selectRelai')}</h1>
                            <button type="button" className="btn-close" onClick={() => {
                                closeModal(modalRef3)
                                openModal(modalRef1)
                            }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ height: "70vh" }}>
                            <RelaiMapSelection location={trajet?.to_address?.location} setRelai={handleRelaiSelection2} nextModalRef={modalRef1} currentModal={modalRef3} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => { closeModal(modalRef3) }}>{t("cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Ajouter_trajet_form