import React, { useRef, useState } from "react";

import InscriptionImg from "../../assets/home_inscription.jpg";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from './connection.module.css'; // Import the CSS module
import { Modal } from "bootstrap/dist/js/bootstrap.min";
import { useTranslation } from "react-i18next";

const Connection = ({ SetIsAuthenticated }) => {
    const { t } = useTranslation()
    console.log("rendering Connection")
    const modalRef = useRef(null);
    const modalRef2 = useRef(null);
    const location = useLocation()
    console.log(location)
    const navigate = useNavigate()
    const signIn = useSignIn()
    const path = location.state?.from || ""
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [errors, setErrors] = useState({ email: false, password: false });
    const [emailExist, setEmailExist] = useState(true)
    const [formData, setFormData] = useState({ email: '', password: '' })
    const [email, setEmail] = useState('')
    const resetPassword = async () => {
        setEmailExist(true)
        if (email === "")
            setEmailExist(false)
        await axios.post(`${process.env.REACT_APP_SERVER}/users/resetPassword`, { email }).then((res) => {
            closeModal(modalRef2);
        }).catch((err) => {
            setEmailExist(false)
            console.log(err)
        })
    }
    const onSubmit = async (e) => {
        try {
            e.preventDefault()
            setEmailError(false);
            setPasswordError(false);
            const emailValid = formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
            const newErrors = {
                email: !emailValid,
                password: !formData.password,
            };
            setErrors(newErrors);

            if (!newErrors.email && !newErrors.password) {
                await axios.post(`${process.env.REACT_APP_SERVER}/users/login`, formData)
                    .then((res) => {
                        if (res.status === 200) {
                            let obj = {}
                            if (signIn({
                                auth: {
                                    token: res.data.token,
                                    type: 'Bearer'
                                },
                                userState: { userRole: res.data.userRole },
                                refresh: res.data.refreshToken
                            })) { // Only if you are using refreshToken feature
                                obj.auth = true
                                obj.role = res.data.userRole
                                console.log(res.data)
                                SetIsAuthenticated(obj)
                                console.log(res.data.userRole)
                                if (path && path != "")
                                    navigate(path, { state: location.state })
                                else if (res.data.userRole === "admin" || res.data.userRole === "superadmin")
                                    navigate("/Dashboard")

                                else navigate("/")
                            }
                        }
                    })
                    .catch((error) => {
                        if (error.response.data.error === 1) {
                            setEmailError(true);
                        } else if (error.response.data.error === 2) {
                            openModal(modalRef)
                        } else if (error.response.data.error === 3) {
                            setPasswordError(true);
                        }
                    })
            }
        }
        catch (error) {
            console.error(error)
        }
    }
    const openModal = (modalRef) => {
        const modalElement = modalRef.current;
        if (modalElement) {
            const modalInstance = new Modal(modalElement);
            modalInstance.show();
        }
    };

    const closeModal = (modalRef) => {
        const modalElement = modalRef.current;
        if (modalElement) {
            const modalInstance = Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }
        }
    };
    const resend = async () => {
        console.log('resend')
        await axios.post(`${process.env.REACT_APP_SERVER}/users/resendCode`, formData)
    }
    return (
        <div className="bg-gradient-primary">
            <div className="container ">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-12 col-xl-10">
                        <div className="card shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-flex">
                                        <div className="flex-grow-1 bg-login-image ">
                                            <img src={InscriptionImg} alt="connection" className="w-100 " />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 align-self-center">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h4 className="text-dark mb-4">{t('login')}</h4>
                                            </div>
                                            <form className="user form needs-validation" onSubmit={onSubmit} noValidate>
                                                <div className="mb-3">
                                                    <input
                                                        className={`form-control form-control-user ${errors.email || emailError ? 'is-invalid' : ''}`}
                                                        type="email" id="exampleInputEmail" aria-describedby="emailHelp" placeholder={t('emailInputPlaceholder')}
                                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                        required name="email" />
                                                    {!emailError && <div className={`invalid-feedback`}>
                                                        {t('emailError')}
                                                    </div>}
                                                    {emailError && <div className={`invalid-feedback`}>
                                                        {t('emailIncorrect')}
                                                    </div>}
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        className={`form-control form-control-user ${errors.password || passwordError ? 'is-invalid' : ''}`}
                                                        type="password" id="exampleInputPassword" placeholder={t('passwordInputPlaceholder')}
                                                        required onChange={(e) => setFormData({ ...formData, password: e.target.value })} name="password" />
                                                    <div className={`invalid-feedback`}>
                                                        {t('passwordIncorrect')}
                                                    </div>
                                                </div>

                                                <button className="btn btn-primary d-block btn-user w-100 mb-2" type="submit">{t('login')}</button>
                                                <div className="text-center"><a className={`small ${styles.pointer}`} onClick={() => { openModal(modalRef2) }}>{t('forgetPassword')}</a></div>
                                                <hr />
                                            </form>
                                            <div className="text-center"><a className="small" href="/inscription">{t('createAccount')}</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" ref={modalRef} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> {t('verifyEmail')}</h5>
                            <button type="button" className="btn-close" onClick={() => { closeModal(modalRef) }} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            {t('verifyEmailText')}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" onClick={resend}> {t('resend')}</button>
                            <button type="button" className="btn btn-primary w-auto" onClick={() => { closeModal(modalRef) }}> {t('emailReceived')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalId2" ref={modalRef2} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{t('resetPassword')}</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(modalRef2)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {t('resetPasswordText')}
                            <input
                                type="email"
                                className={`form-control ${!emailExist ? "is-invalid" : ""}`}
                                placeholder={t('emailInputPlaceholder')}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="invalid-feedback">
                                {t('emailError')}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" onClick={() => closeModal(modalRef2)}>{t('cancel')}</button>
                            <button type="button" className="btn btn-primary w-auto" onClick={() => {
                                resetPassword();
                            }}>{t('send')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

};

export default Connection;