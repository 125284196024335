import { useEffect, useRef, useState } from "react";
import PayPalLogin from "../../payement/addAcount";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import { Modal } from "bootstrap/dist/js/bootstrap.min";
import PaginationComponent from "../../../components/paginations/pagination";
import { useTranslation } from "react-i18next";
import style from "../style.module.css"
function MesPaiements() {
    const { t } = useTranslation()
    const columns = t('myPaymentsCols', { returnObjects: true })
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState()
    const [paiements, setPaiements] = useState([])
    const [accounts, setAccounts] = useState([])
    const [selectedPayment, setSelectedPayment] = useState(null)
    const modalRef = useRef(null)
    const modalRef1 = useRef(null)
    const modalRef2 = useRef(null)
    const modalRef3 = useRef(null)
    const token = window.localStorage.getItem("_auth");
    const [mode, setMode] = useState(null)
    const accountColumns = t('paymentMethodCols', { returnObjects: true })
    const fetchCount = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/userPaiments/userCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData = async () => {
        const eventSource = new EventSource(`${process.env.REACT_APP_SERVER}/userPaiments?token=${token}&page=${page}`);

        eventSource.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log("Received event data:", data);

                // Use the latest selectedPayment value
                setSelectedPayment(prevSelectedPayment => {
                    if (prevSelectedPayment && data._id === prevSelectedPayment._id) {
                        console.log("Updating selected payment");
                        // Handle modal interactions based on payment state
                        if (data.etat === "payé") {
                            closeModal();
                            openPaymentSuccess();
                        } else if (data.etat === "refusé") {
                            closeModal();
                            openPaymentFailed();
                        }
                        return data; // Update the selectedPayment to the new data
                    }

                    // If selectedPayment doesn't match, return the previous value
                    return prevSelectedPayment;
                });

                // Update or add payment to the list
                setPaiements((prevPaiements) => {
                    const existingPaymentIndex = prevPaiements.findIndex((p) => p._id === data._id);
                    if (existingPaymentIndex !== -1) {
                        // Update the existing payment
                        const updatedPaiements = [...prevPaiements];
                        updatedPaiements[existingPaymentIndex] = data;
                        return updatedPaiements;
                    } else {
                        // Add the new payment
                        return [...prevPaiements, data];
                    }
                });
            } catch (error) {
                console.error("Error processing event data:", error);
            }
        };

        // Handle EventSource errors
        eventSource.onerror = (error) => {
            console.error("EventSource error:", error);
            eventSource.close();
        };

        // Cleanup EventSource on component unmount
        return () => {
            eventSource.close();
        };
    };



    const openPayModal = () => {
        const modal = new Modal(modalRef.current)
        modal.show()
    }
    const handlePayClick = async (paiement) => {
        console.log("setting selectedPayment", paiement)
        setSelectedPayment(paiement)
        await axios.get(`${process.env.REACT_APP_SERVER}/userPaiments/receive/${paiement._id}`).then(response => {
            console.log(response.data)
            openPayModal()
        }).catch(error => {
            console.log(error)
        })
    }
    const openAccountValidate = () => {
        const modal = new Modal(modalRef1.current)
        modal.show()
    }
    const openPaymentSuccess = () => {
        const modal = new Modal(modalRef2.current)
        modal.show()
    }
    const openPaymentFailed = () => {
        const modal = new Modal(modalRef3.current)
        modal.show()
    }
    const closeModal = () => {
        console.log("close")
        const modal = Modal.getInstance(modalRef.current)
        modal.hide()
    }
    const handlePay = async () => {
        if (mode === null) {
            openAccountValidate()
        }
        const payer = accounts[mode]
        console.log(payer)
        await axios.post(`${process.env.REACT_APP_SERVER}/payments/getPay/${selectedPayment._id}`, payer)
            .then(async response => {
            }).catch(error => {
                console.log(error)
            })
    }
    useEffect(() => {
        fetchCount();
        fetchData(page);
    }, []); // Only run once on mount

    return (
        <Row style={{ maxWidth: "100%" }} >
            <div className="mb-3 mt-1 w-100 p-0">
                <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    {t('managePaymentMethod')}
                </button>
                <div className="collapse" id="collapseExample">
                    <Card className="Card col-12 mx-auto " style={{ maxWidth: "100%" }}  >
                        <CardBody className="pt-4">
                            <PayPalLogin accounts={accounts} setAccounts={setAccounts} />
                        </CardBody>
                    </Card>
                </div>
            </div>
            <Card className="Card" style={{ maxWidth: "100%" }}  >
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">{t('PaymentsList')}</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map((column, index) => (
                                        <th key={index}>{column}</th>
                                    ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {paiements.length > 0 && paiements.map((paiement, index) => (
                                    <tr key={index}>
                                        <td>{new Date(paiement.date_creation).toLocaleDateString()}</td>
                                        <td>{paiement.montant}</td>
                                        <td>{paiement.etat}</td>
                                        <td>
                                            {paiement.etat === "en attente" || paiement.etat === "refusé" ? (
                                                <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>

                                                    <button className="btn btn-outline-success btn-sm col-12" onClick={() => handlePayClick(paiement)}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.05 16.25H11.17C9.84001 16.25 8.75 15.13 8.75 13.75C8.75 13.34 9.09 13 9.5 13C9.91 13 10.25 13.34 10.25 13.75C10.25 14.3 10.66 14.75 11.17 14.75H13.05C13.44 14.75 13.75 14.4 13.75 13.97C13.75 13.43 13.6 13.35 13.26 13.23L10.25 12.18C9.61 11.96 8.75 11.49 8.75 10.02C8.75 8.76999 9.74001 7.73999 10.95 7.73999H12.83C14.16 7.73999 15.25 8.85999 15.25 10.24C15.25 10.65 14.91 10.99 14.5 10.99C14.09 10.99 13.75 10.65 13.75 10.24C13.75 9.68999 13.34 9.23999 12.83 9.23999H10.95C10.56 9.23999 10.25 9.58999 10.25 10.02C10.25 10.56 10.4 10.64 10.74 10.76L13.75 11.81C14.39 12.03 15.25 12.5 15.25 13.97C15.25 15.23 14.26 16.25 13.05 16.25Z" fill="currentColor" fill-opacity="0.88" />
                                                            <path d="M12 17.25C11.59 17.25 11.25 16.91 11.25 16.5V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V16.5C12.75 16.91 12.41 17.25 12 17.25Z" fill="currentColor" fill-opacity="0.88" />
                                                            <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2C12.75 2.41 12.41 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 11.59 21.59 11.25 22 11.25C22.41 11.25 22.75 11.59 22.75 12C22.75 17.93 17.93 22.75 12 22.75Z" fill="currentColor" fill-opacity="0.88" />
                                                            <path d="M21 7.75H17C16.59 7.75 16.25 7.41 16.25 7V3C16.25 2.59 16.59 2.25 17 2.25C17.41 2.25 17.75 2.59 17.75 3V6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z" fill="currentColor" fill-opacity="0.88" />
                                                            <path d="M16.9999 7.74994C16.8099 7.74994 16.6199 7.67994 16.4699 7.52994C16.1799 7.23994 16.1799 6.75994 16.4699 6.46994L21.4699 1.46994C21.7599 1.17994 22.2399 1.17994 22.5299 1.46994C22.8199 1.75994 22.8199 2.23994 22.5299 2.52994L17.5299 7.52994C17.3799 7.67994 17.1899 7.74994 16.9999 7.74994Z" fill="currentColor" fill-opacity="0.88" />
                                                        </svg>

                                                    </button>
                                                </div>
                                            ) : (
                                                <button className="btn btn-outline-secondary btn-sm col-12" disabled>Aucune</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <PaginationComponent page={page} lastPage={lastPage} setPage={setPage} fetchData={fetchData} />

            </Card>
            <div className="modal fade" id="modalId" ref={modalRef} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                {t('receivePayment')}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h4>{t('selectAccount')}</h4>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        {accountColumns.map((column, index) => (
                                            <th key={index}>{column}</th>
                                        )
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        accounts.map((account, index) => (
                                            <tr key={index + 1}>
                                                <td><input type="radio" className="form-check-input my-auto" name="mode" checked={mode === index} value={index} onChange={() => setMode(index)} /></td>
                                                <td><p className="mb-0">{account.email_address}</p> </td>
                                                <td>{account.name.surname}</td>
                                                <td>{account.name.given_name}</td>
                                            </tr>
                                        ))
                                    }</tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-outline-secondary w-auto"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setMode(null); // Reset mode
                                }}
                            >
                                {t("cancel")}
                            </button>
                            {selectedPayment && selectedPayment.etat === "en cours" && (
                                <button className="btn btn-primary w-auto" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    <span role="status">{t('pending')}</span>
                                </button>
                            )}
                            {selectedPayment && (selectedPayment.etat === "en attente" || selectedPayment.etat === "refusé") && (
                                <button
                                    type="button"
                                    className="btn btn-success w-auto"
                                    onClick={handlePay}
                                >
                                    {t('endPayment')}
                                </button>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="modalId"
                tabIndex="-1"
                ref={modalRef1}
                data-bs-backdrop="static"
                data-bs-keyboard="false"

                role="dialog"
                aria-labelledby="modalTitleId"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                {t('paymentError')}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div
                                className="alert alert-danger"
                                role="alert"
                            >
                                {t('paymentErrorText')}
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-ouline-secondary w-auto"
                                data-bs-dismiss="modal"
                            >
                                {t("close")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalId3" ref={modalRef2} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close ms-auto m-3"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6 mx-auto my-4">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#198754" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline className="path check" fill="none" stroke="#198754" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                </div>
                            </div>
                            <p className="mt-3 text-center">
                                {t('paymentSuccessText')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalId4" tabIndex="-1" ref={modalRef3} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <button type="button" className="btn-close ms-auto m-3" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6 mx-auto my-4">
                                    <svg version="1.1" className="card-img-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#db3646" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <line className="path line" fill="none" stroke="#db3646" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                        <line className="path line" fill="none" stroke="#db3646" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="37.9" x2="34.4" y2="92.3" />
                                    </svg>
                                </div>
                            </div>
                            <p className="mt-3 text-center">
                                {t('paymentErrorText2')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Row>

    );
}
export default MesPaiements;