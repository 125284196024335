import { useEffect, useRef, useState } from "react";
import styles from './chat.module.css';
import ChatBox from "./ChatBox";
import { useTranslation } from "react-i18next";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";

function ChatRow({ chatsBalls, setChatsBalls, openedChats, setOpenedChats }) {
    const [selectedChat, setSelectedChat] = useState(undefined)
    const { t } = useTranslation()
    const [width, setWidth] = useState(window.innerWidth);
    const modalRef = useRef(null)
    const modalRef2 = useRef(null)
    useEffect(() => {
        if (selectedChat) {
            if (selectedChat.offreType === "TtrajetOffre") {
                openModal(modalRef2)
            }
            if (selectedChat.offreType === "TrajetOffre") {
                openModal(modalRef)
            }
        }
    }, [selectedChat])
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide()
    }
    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            setWidth(currentWidth);

            if (currentWidth < 780) {
                setOpenedChats(prevChats => (prevChats.length > 1 ? prevChats.slice(-1) : prevChats));
            }
        };

        // Set initial width and openedChats based on current window width
        handleResize();

        window.addEventListener('resize', handleResize);
    }, [openedChats]);

    const addChat = async (chatId) => {
        if (openedChats.includes(chatId)) return;
        console.log(chatId);
        setOpenedChats([...openedChats, chatId]);
    }
    const getChatBallLetter = (chat) => {
        return chat.receiver.nom[0].toUpperCase() + chat.receiver.prenom[0].toUpperCase();
    }
    return (
        <div className={`${styles.fixedContainer} position-fixed d-flex justify-content-end`}>
            <div className={`${styles.chatBoxContainer} d-flex align-items-end justify-content-end`}>
                {openedChats.map((chat, index) => (
                    <ChatBox key={index} chat={chat} setChats={setOpenedChats} setSelectedChat={setSelectedChat} />
                ))}
            </div>
            <div className={`${styles.chatBallContainer} d-flex align-items-end`}>
                {chatsBalls.map((chatBall, index) => (
                    <div
                        role="button"
                        onClick={() => addChat(chatBall)}
                        key={index}
                        className={`${styles.chatBall} rounded-circle`}
                    >
                        <p className={styles.chatBallText}>{getChatBallLetter(chatBall)}</p>
                    </div>
                ))}
            </div>

            <div className="modal modal-md fade " ref={modalRef} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" >
                    <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                        <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                            <h1 className="modal-title fs-5 text-light" id="exampleModalLabel">{t('consultOffre')}</h1>
                            <button type="button" className="btn-close" onClick={() => {
                                closeModal(modalRef)
                                setSelectedChat(undefined)
                            }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" >
                            {(selectedChat && selectedChat.offreType === "TtrajetOffre") && <div className="row">
                                {(selectedChat.offre.acceptedOffre == "counterOffre") && <div className="col ">

                                    <div className="card rounded-5 p-3 h-100" >
                                        <h5 className="card-title">{t('transportOffre')}</h5>
                                        <div className="card-body">
                                            <h6 className="fw-bold">{t('start')}</h6>
                                            <p>
                                                {t('startH2H')} {selectedChat && selectedChat.offre.counterOffre.mam1 === true ? t('yes') : t('no')}
                                            </p>
                                            <p>
                                                {t('startRelai')} {(selectedChat && selectedChat.offre.counterOffre.relai1) ? (selectedChat.offre.counterOffre.relai1.address) : (t('no'))}
                                            </p>
                                            <h6 className="fw-bold">{t('end')}</h6>
                                            <p>
                                                {t('endH2H')} {selectedChat && selectedChat.offre.counterOffre.mam2 === true ? t('yes') : t('no')}
                                            </p>
                                            <p>
                                                {t('endRelai')} {(selectedChat && selectedChat.offre.counterOffre.relai2) ? (selectedChat.offre.counterOffre.relai2.address) : (t('no'))}
                                            </p>
                                            <div> <b>{t('price')} : </b> {(selectedChat && selectedChat.offre.counterOffre.prix) ? (selectedChat.offre.counterOffre.prix) : (t('notFound'))}</div>

                                        </div>
                                        <div className="card-body"></div>
                                    </div>
                                </div>}
                                {(selectedChat.offre.acceptedOffre == "offre") &&
                                    <div className="col">
                                        <div className="card rounded-5 p-3 h-100" >
                                            <h5 className="card-title">{t('partOffre')}</h5>
                                            <div className="card-body">
                                                <fieldset>
                                                    <h6 className="fw-bold">{t('start')}</h6>
                                                    <div className="d-flex">
                                                        <p className="me-4">{t('startH2H')}  {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.mam1 ? t('yes') : t('no')}</p>


                                                    </div>
                                                    <div className="d-flex">
                                                        <p className="me-4">{t('startRelai')} {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.relai1 ? selectedChat.offre.offre.relai1.address : t('no')} </p>
                                                    </div>

                                                </fieldset>

                                                <fieldset>
                                                    <h6 className="fw-bold">{t('end')}</h6>

                                                    <div className="d-flex">
                                                        <p className="me-4">{t('endH2H')} {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.mam2 ? t('yes') : t('no')}</p>

                                                    </div>

                                                    <div className="d-flex">
                                                        <p className="me-4">{t('endRelai')}{selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.relai2 ? selectedChat.offre.offre.relai2.address : t('no')}</p>

                                                    </div>

                                                </fieldset>
                                                <fieldset>
                                                    <div> <b>{t('price')} : </b> {(selectedChat && selectedChat.offre.offre.prix) ? (selectedChat.offre.offre.prix) : (t('notFound'))}</div>

                                                </fieldset>
                                            </div>


                                        </div>

                                    </div>
                                }
                            </div>}
                            {(selectedChat && selectedChat.offreType === "TrajetOffre") &&
                                <div className="row">
                                    <div className="col ">
                                        <div className="card rounded-5 p-3 h-100" >
                                            <h5 className="card-title">{t('trajetOffre')}</h5>
                                            <div className="card-body">
                                                <h6 className="fw-bold">{t('start')}</h6>
                                                <p>
                                                    {t('departure')} {selectedChat.trajet.from_address.name}
                                                </p>
                                                <p>
                                                    {t('departureDate')} {new Date(selectedChat.trajet.go_time).toLocaleDateString('fr-FR')}
                                                </p>
                                                <h6 className="fw-bold">{t('end')}</h6>
                                                <p>
                                                    {t('arrival')} {selectedChat.trajet.to_address.name}
                                                </p>
                                                <p>
                                                    {t('arrivalDate')} {new Date(selectedChat.trajet.arrive_time).toLocaleDateString('fr-FR')}
                                                </p>
                                                <div> <b>{t('price')} : </b> {selectedChat.offre.prix}</div>

                                            </div>
                                            <div className="card-body"></div>
                                        </div>
                                    </div>


                                </div>}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary w-auto"
                                onClick={() => {
                                    closeModal(modalRef)
                                    setSelectedChat(undefined)
                                }}>{t("cancel")}</button>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal modal-xl fade " ref={modalRef2} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" >
                    <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                        <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                            <h1 className="modal-title fs-5 text-light" id="exampleModalLabel">{t('consultOffre')}</h1>
                            <button type="button" className="btn-close" onClick={() => {
                                closeModal(modalRef2)
                                setSelectedChat(undefined)
                            }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" >
                            {(selectedChat && selectedChat.offreType === "TtrajetOffre") && <div className="row">
                                <div className="col ">
                                    <div className="card rounded-5 p-3 h-100" >
                                        <h5 className="card-title">{t('transportOffre')}</h5>
                                        <div className="card-body">
                                            <h6 className="fw-bold">{t('start')}</h6>
                                            <p>
                                                {t('startH2H')} {selectedChat && selectedChat.offre.counterOffre.mam1 === true ? t('yes') : t('no')}
                                            </p>
                                            <p>
                                                {t('startRelai')} {(selectedChat && selectedChat.offre.counterOffre.relai1) ? (selectedChat.offre.counterOffre.relai1.address) : (t('no'))}
                                            </p>
                                            <h6 className="fw-bold">{t('end')}</h6>
                                            <p>
                                                {t('endH2H')} {selectedChat && selectedChat.offre.counterOffre.mam2 === true ? t('yes') : t('no')}
                                            </p>
                                            <p>
                                                {t('endRelai')} {(selectedChat && selectedChat.offre.counterOffre.relai2) ? (selectedChat.offre.counterOffre.relai2.address) : (t('no'))}
                                            </p>
                                            <div> <b>{t('price')} : </b> {(selectedChat && selectedChat.offre.counterOffre.prix) ? (selectedChat.offre.counterOffre.prix) : (t('notFound'))}</div>

                                        </div>
                                        <div className="card-body"></div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card rounded-5 p-3 h-100" >
                                        <h5 className="card-title">{t('partOffre')}</h5>
                                        <div className="card-body">
                                            <fieldset>
                                                <h6 className="fw-bold">{t('start')}</h6>
                                                <div className="d-flex">
                                                    <p className="me-4">{t('startH2H')}  {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.mam1 ? t('yes') : t('no')}</p>


                                                </div>
                                                <div className="d-flex">
                                                    <p className="me-4">{t('startRelai')} {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.relai1 ? selectedChat.offre.offre.relai1.address : t('no')} </p>
                                                </div>

                                            </fieldset>

                                            <fieldset>
                                                <h6 className="fw-bold">{t('end')}</h6>

                                                <div className="d-flex">
                                                    <p className="me-4">{t('endH2H')} {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.mam2 ? t('yes') : t('no')}</p>

                                                </div>

                                                <div className="d-flex">
                                                    <p className="me-4">{t('endRelai')}{selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.relai2 ? selectedChat.offre.offre.relai2.address : t('no')}</p>

                                                </div>

                                            </fieldset>
                                            <fieldset>
                                                <div> <b>{t('price')} : </b> {(selectedChat && selectedChat.offre.offre.prix) ? (selectedChat.offre.offre.prix) : (t('notFound'))}</div>

                                            </fieldset>
                                        </div>


                                    </div>

                                </div>

                            </div>}

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary w-auto"
                                onClick={() => {
                                    closeModal(modalRef2)
                                    setSelectedChat(undefined)
                                }}>{t("cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ChatRow;