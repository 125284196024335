import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';

function RequireAuthAndRole({ children, roles }) {
    const [auth, setAuth] = useState(useIsAuthenticated());
    const navigate = useNavigate();
    const location = useLocation();
    const user = useAuthUser();
    const userRole = user?.userRole;

    useEffect(() => {
        if ((auth && !roles.includes(userRole)) || roles.length === 0) {
            navigate('/unauthorized', { state: { from: location } });
        }
    }, [auth, userRole, navigate, roles, location]);

    if (!auth) {
        return (
            <Navigate
                to="/connection"
                state={{ ...location.state, from: location.pathname }}
                replace
            />
        );
    }

    // Render children if authenticated and authorized
    return roles.includes(userRole) ? children : null;
}

export default RequireAuthAndRole;