import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

function Report() {
    const location = useLocation();
    const trajet = location.state;
    const { t } = useTranslation()
    const reports = t('reportsList', { returnObjects: true })
    const [selectedReport, setSelectedReport] = useState()
    const handleChange = (event) => {
        try {
            console.log(event.target.value)
            setSelectedReport(event.target.value)
        } catch (err) {
            console.log(err)
        }
    }
    const navigate = useNavigate()
    const submit = async (event) => {
        try {
            console.log('submiting')
            let report = {
                subject: trajet._id,
                content: selectedReport,
                subjectType: trajet.transporteur ? "Ttrajet" : 'Trajet'
            }
            await axios.post(`${process.env.REACT_APP_SERVER}/reports`, report).then((res) => {
                navigate('/')
                console.log(res)
            })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6 mx-auto mt-4">
                    <h1 className="mb-4"> {t('reportForThisReason')}</h1 >
                    <form className="form ">
                        {Object.entries(reports).map(([key, value], index) => (
                            <>
                                <input
                                    type="radio"
                                    className="btn-check"
                                    name="options-base"
                                    onChange={handleChange}
                                    value={key}
                                    id={`option${index}`}
                                    autoComplete="off"
                                />
                                <label className="btn btn-outline-secondary mb-3" htmlFor={`option${index}`}>
                                    {value}
                                </label>
                            </>
                        ))}

                        <button className="btn btn-primary col-12" type="button" onClick={submit}> {t('report')}</button>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default Report;